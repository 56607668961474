import { defineStore, acceptHMRUpdate } from 'pinia';
import { Editor } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Image from '@/TiptapExtensions/image';
import Link from "@/TiptapExtensions/link";
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import CharacterCount from '@tiptap/extension-character-count';
import AutoSuggestionText from '@/TiptapExtensions/AutoSuggestionText.ts';
import SuggestionNode from '@/TiptapExtensions/SuggestionNode.ts';

export const useEditorStore = defineStore('editor', {
    state: () => ({
        editor: null,
        linkModal: false,
        currentLink: {
            url: '',
            target: '',
        },
        selectedText: '',
        selectedCharacters: 0,
    }),
    getters: {
        getEditor: (state) => state.editor,
        getLinkModal: (state) => state.linkModal,
        getCurrentLink: (state) => state.currentLink,
        getCurrentLinkHref: (state) => state.currentLink.href,
        getCurrentLinkTarget: (state) => state.currentLink.target,
        getSelectedText: (state) => state.selectedText,
        getSelectedCharacters: (state) => state.selectedCharacters,
    },
    actions: {
        initializeEditor({ onCreate, onSelectionUpdate, onUpdate }) {
            this.editor = new Editor({
                extensions: [
                    StarterKit,
                    Table.configure({
                        resizable: true,
                    }),
                    TableRow,
                    TableHeader,
                    TableCell,
                    Image,
                    CharacterCount,
                    Link,
                    AutoSuggestionText.configure({
                        HTMLAttributes: {
                            class: 'autocomplete-suggestion',
                        },
                    }),
                    SuggestionNode,
                ],
                autofocus: true,
                onCreate: ({ editor }) => {
                    onCreate?.(editor);
                },
                onSelectionUpdate: ({ editor }) => {
                    onSelectionUpdate?.(editor);
                },
                onUpdate: ({ editor }) => {
                    onUpdate?.(editor);
                },
                editorProps: {
                    handleDOMEvents: {
                        keydown: (view, event) => {
                            // eslint-disable-next-line no-undef
                            emitter.emit('editor-store-dom-events-key-down', {
                                view,
                                event,
                            });
                        },
                    },
                },
            });
        },

        setEditorContent(content) {
            if (!this.editor) {
                console.error("Editor instance not found.");
                return;
            }
            this.editor.commands.setContent(content);
        },
        insertContentAt(position, content, settings) {
            if (!this.editor) {
                console.error('Editor instance not found.');

                return;
            }
            this.editor.commands.insertContentAt(position, content, settings);
        },
        insertText(text) {
            if (!this.editor) {
                console.error('Editor instance not found.');

                return;
            }

            const transaction = this.editor.state.tr.insertText(text);
            this.editor.view.dispatch(transaction);
        },
        setSuggestion(suggestion) {
            if (!this.editor) {
                console.error('Editor instance not found.');

                return;
            }

            this.editor.commands.setSuggestion(suggestion);
        },

        destroyEditor() {
            this.editor?.destroy();
            this.editor = null;
        },

        setLinkModal(show) {
            this.linkModal = show;
        },

        setCurrentLink(link) {
            this.currentLink.href = link.href;
            this.currentLink.target = link.target;
        },

        setCurrentLinkHref(href) {
            this.currentLink.href = href;
        },

        setCurrentLinkTarget(target) {
            this.currentLink.target = target;
        },

        updateSelectedText(text) {
            this.selectedText = text;
        },

        updateSelectedCharacters(count) {
            this.selectedCharacters = count;
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useEditorStore, import.meta.hot))
}