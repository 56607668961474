export function useClipboard() {

    // Function to copy content to the clipboard
    const copy = (content) => {
        // Create a temporary div element to hold the content
        let tempElement = document.createElement('div');
        tempElement.innerHTML = content; // Set the inner HTML to the content to be copied
        document.body.appendChild(tempElement); // Append the temporary element to the body

        // Create a range and select the contents of the temporary element
        let range = document.createRange();
        range.selectNodeContents(tempElement);
        let selection = window.getSelection();
        selection.removeAllRanges(); // Clear any existing selections
        selection.addRange(range); // Add the new range to the selection

        // Execute the copy command
        document.execCommand('copy');
        document.body.removeChild(tempElement); // Remove the temporary element from the body
    }

    return {
        copy // Return the copy function for external use
    };
}