<template>
    <div
        class="d-flex flex-column flex-grow-1 bg-white p-3 p-md-5 p-lg-8 border border-gray-300 overflow-y-auto live-search-container"
    >
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="fw-bold fs-5">{{ __('Source') }}</div>
            <button
                class="btn btn-icon btn-sm btn-white"
                @click="$emit('close')"
            >
                <font-awesome-icon
                    class="text-inherit"
                    :icon="['fas', 'xmark']"
                />
            </button>
        </div>

        <ul class="p-0 list-unstyled search-results-list">
            <li
                class="search-result-item my-1 py-3 px-3"
                v-for="(result, index) in liveSearchResult"
                :key="index"
                @click="openLink(result.link)"
            >
                <div class="text-black d-flex align-items-center">
                    <img
                        :src="getFavicon(result.domain)"
                        class="favicon-img me-2"
                        width="16"
                        height="16"
                        :alt="result.domain + '-logo'"
                    />
                    <span class="domain-text">{{ result.domain }}</span>
                </div>
                <div class="result-title mt-1">
                    {{ result.title }}
                </div>
                <div class="result-snippet mt-1">{{ result.snippet }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        liveSearchResult: {
            type: Array,
            required: true,
        },
    },

    methods: {
        getFavicon(domain) {
            return `https://www.google.com/s2/favicons?domain=https://${domain}&size=16`;
        },
        __(text) {
            return window.__ ? window.__(text) : text;
        },
        openLink(url) {
            window.open(url, '_blank');
        },
    },
};
</script>

<style lang="scss">
.live-search-container {
    position: fixed;
    top: 66px;
    right: 0;
    width: 100%;
    max-width: 400px;
    height: calc(100vh - 66px);
    z-index: 1000;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);

    @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
    }
}

.search-results-list {
    margin-top: 0.5rem;
}

.search-result-item {
    border-radius: 8px;
    transition: background-color 0.2s ease;
    cursor: pointer;
    position: relative;
    border: 1px solid transparent;

    &:hover {
        background-color: #f8f9fa;
        border-color: #e9ecef;
    }

    &:active {
        background-color: #f1f3f5;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #e9ecef;
    }

    &:last-child::after {
        display: none;
    }
}

.favicon-img {
    border-radius: 50%;
    flex-shrink: 0;
}

.domain-text {
    color: #6c757d;
    font-size: 0.85rem;
}

.result-title {
    color: #212529;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.result-snippet {
    color: #495057;
    font-size: 0.875rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
