import { defineStore } from 'pinia';
import { debounce } from 'lodash';

function getDefaultState() {
    return {
        articleWizardType: '',
        loading: false,
        isModalOpen: false,
        shouldCheckForOngoingArticle: false,
        context: '',
        focusKeyword: '',
        searchEngine: null,
        language: 'en',
        formality: 'default',
        openAiOptions: {
            engine: 'gpt-4o-mini',
            creativity: 0.75,
            outputs: 3,
            withEmojis: false,
            frequency_penalty: '0.5',
            presence_penalty: '0.5',
        },
        url: '',
        parsedUrlTaskId: null,
        parsedUrlTitles: [],
        parsedUrlContent: [],
        parsedUrlOutline: [],
        serpBeaterMode: false,
        serpBeater: {},
        serpBeaterCompetitors: [],
        organicTypeSerpBeaterCompetitors: [],
        selectedSerpBeaterCompetitorsId: [],
        articleTitle: '',
        previousArticleTitle: '',
        aiGeneratedTitles: [],
        articleTitleDragging: false,
        articleOutline: '',
        headingLists: [],
        currentH2Id: null,
        selectedFaqList: [],
        aiGeneratedOutlines: [],
        aiGeneratedH2Ideas: [],
        aiGeneratedH3Ideas: {},
        aiGeneratedFaqIdeas: [],
        topRankedOutlines: [],
        topRankedOutlineFaqs: [],
        articleOutlineRequestPayload: {},
        aiGeneratedWritingPoints: [],
        articleDraftContent: {
            introduction: [],
            heading_lists: [],
            faq_lists: [],
            conclusion: [],
        },
        articleDraftPreviousContent: [],
        currentStep: 0,
        finishedStep: -1,
        isError:false,
    };
}

export const useArticleWizardStore = defineStore('articleWizard', {
    state: () => {
        const savedState = localStorage.getItem('articleWizardState');
        return savedState ? JSON.parse(savedState) : getDefaultState();
    },

    getters: {
        getArticleWizardType: (state) => state.articleWizardType,
        isLoading: (state) => state.loading,
        getIsModalOpen: (state) => state.isModalOpen,
        getContext: (state) => state.context,
        getFocusKeyword: (state) => state.focusKeyword,
        getSearchEngine: (state) => state.searchEngine,
        getLanguage: (state) => state.language,
        getFormality: (state) => state.formality,
        getOpenAiOptions: (state) => state.openAiOptions,
        getUrl: (state) => state.url,
        getParsedUrlTaskId: (state) => state.parsedUrlTaskId,
        getParsedUrlTitles: (state) => state.parsedUrlTitles,
        getParsedUrlContent: (state) => state.parsedUrlContent,
        getParsedUrlOutline: (state) => state.parsedUrlOutline,
        getSerpBeaterMode: (state) => state.serpBeaterMode,
        getSerpBeater: (state) => state.serpBeater,
        getSerpBeaterCompetitors: (state) => state.serpBeaterCompetitors,
        getOrganicTypeSerpBeaterCompetitors: (state) => {
            if (state.organicTypeSerpBeaterCompetitors?.length) {
                return state.organicTypeSerpBeaterCompetitors;
            }

            return state.serpBeaterCompetitors?.map((competitor) => {
                return { h: competitor.title, rank: competitor.rank_absolute };
            });
        },
        getSelectedSerpBeaterCompetitorsId: (state) =>
            state.selectedSerpBeaterCompetitorsId,
        getArticleTitle: (state) => state.articleTitle,
        getPreviousArticleTitle: (state) => state.previousArticleTitle,
        getAiGeneratedTitles: (state) => state.aiGeneratedTitles,
        getTopRankedOutlines: (state) => state.topRankedOutlines,
        getTopRankedOutlineFaqs: (state) => state.topRankedOutlineFaqs,
        getArticleTitleDragging: (state) => state.articleTitleDragging,
        getArticleOutline: (state) => state.articleOutline,
        getHeadingLists: (state) => state.headingLists,
        getCurrentH2Id: (state) => state.currentH2Id,
        getSelectedFaqList: (state) => state.selectedFaqList,
        getAiGeneratedOutlines: (state) => state.aiGeneratedOutlines,
        getAiGeneratedH2Ideas: (state) => state.aiGeneratedH2Ideas,
        getAiGeneratedH3Ideas: (state) => state.aiGeneratedH3Ideas,
        getAiGeneratedFaqIdeas: (state) => state.aiGeneratedFaqIdeas,
        getArticleOutlineRequestPayload: (state) =>
            state.articleOutlineRequestPayload,
        getAiGeneratedWritingPoints: (state) => state.aiGeneratedWritingPoints,
        getArticleDraftContent: (state) => state.articleDraftContent,
        getArticleDraftPreviousContent: (state) => state.articleDraftPreviousContent,
        getCurrentStep: (state) => state.currentStep,
        getFinishedStep: (state) => state.finishedStep,
        hasOngoingArticle: (state) => {
            return (
                state.context !== '' ||
                state.articleTitle !== '' ||
                state.aiGeneratedTitles.length > 0
            );
        },
    },
    actions: {
        toggleCheckForOngoingArticle(state) {
            state.shouldCheckForOngoingArticle = !state.shouldCheckForOngoingArticle;
        },
        async loadStateFromDatabase(uuid) {
            await axios
                .get(`/api/serp-beaters/wizard-progress/${uuid}`)
                .then((response) => {
                    if (response.data) {
                        const wizardData = response.data;

                        // Normalize writing points structure if they exist
                        if (wizardData.aiGeneratedWritingPoints?.length) {
                            wizardData.aiGeneratedWritingPoints = wizardData.aiGeneratedWritingPoints.map(point => {
                                if (point.heading_type_3) {
                                    // Normalize each H3 heading
                                    point.heading_type_3 = point.heading_type_3.map(h3Point => {
                                        // Find the actual heading title (it's the property that contains an array)
                                        const headingTitle = Object.keys(h3Point).find(key =>
                                            Array.isArray(h3Point[key]) &&
                                            !['content_type', 'secondary_keywords'].includes(key)
                                        );

                                        if (headingTitle) {
                                            // Return normalized structure
                                            return {
                                                [headingTitle]: h3Point[headingTitle],
                                                primary_keyword: h3Point.primary_keyword || '',
                                                paragraph_length: h3Point.paragraph_length || '100-150',
                                                num_sections: h3Point.num_sections || 1,
                                                content_type: h3Point.content_type || 'paragraph',
                                                secondary_keywords: h3Point.secondary_keywords || []
                                            };
                                        }
                                        return h3Point;
                                    });
                                }
                                return point;
                            });
                        }

                        Object.assign(this.$state, wizardData);

                        if (!this.getTopRankedOutlines.some(
                            (entry) => entry.data !== null && entry.data !== undefined
                        )) {
                            this.setTopRankedOutlines([]);
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error loading from database:', error);
                });
        },
        saveStateToLocalStorage(finished = false) {
            let articleWizardType = this.getArticleWizardType;
            //don't save the article wizard type to the local storage. It may cause multiple unwanted API calls.
            this.setArticleWizardType('');
            localStorage.setItem(
                'articleWizardState',
                JSON.stringify(this.$state)
            );

            if (this.getSerpBeaterMode && this.isModalOpen) {
                this.saveStateToDatabase();
            }

            //Restore old state.
            this.setArticleWizardType(articleWizardType);
        },
        saveStateToDatabase: debounce(async function (finished = false) {
            let data = {
                uuid: this.serpBeater.uuid,
                wizard_status: finished ? 'completed' : 'in_progress',
                wizard_data: JSON.stringify(this.$state),
            };
            axios
                .post('/api/serp-beaters/wizard-progress', data)
                .then((response) => {
                    console.log('Saved to database');
                })
                .catch((error) => {
                    console.error('Error saving to database:', error);
                });
        }, 1000),
        clearSavedState() {
            localStorage.removeItem('articleWizardState');
        },
        setArticleWizardType(value) {
            this.articleWizardType = value;
        },
        setLoading(value) {
            this.loading = value;
            this.saveStateToLocalStorage();
        },
        setIsModalOpen(value) {
            this.isModalOpen = value;
            this.saveStateToLocalStorage();
        },
        setContext(value) {
            this.context = value;
            this.saveStateToLocalStorage();
        },
        setFocusKeyword(value) {
            this.focusKeyword = value;
            this.saveStateToLocalStorage();
        },
        setSearchEngine(value) {
            this.searchEngine = value;
            this.saveStateToLocalStorage();
        },
        setLanguage(value) {
            this.language = value;
            this.saveStateToLocalStorage();
        },
        setFormality(value) {
            this.formality = value;
            this.saveStateToLocalStorage();
        },
        setUrl(value) {
            this.url = value;
            this.saveStateToLocalStorage();
        },
        setParsedUrlTaskId(value) {
            this.parsedUrlTaskId = value;
            this.saveStateToLocalStorage();
        },
        setParsedUrlTitles(value) {
            this.parsedUrlTitles = value;
            this.saveStateToLocalStorage();
        },
        setParsedUrlContent(value) {
            this.parsedUrlContent = value;
            this.saveStateToLocalStorage();
        },
        setParsedUrlOutline(value) {
            this.parsedUrlOutline = value;
            this.saveStateToLocalStorage();
        },
        setSerpBeaterMode(value) {
            this.serpBeaterMode = value;
            this.saveStateToLocalStorage();
        },
        setSerpBeater(serpBeater) {
            this.serpBeater = serpBeater;
            this.saveStateToLocalStorage();
        },
        setSerpBeaterCompetitors(serpBeaterCompetitors) {
            this.serpBeaterCompetitors = serpBeaterCompetitors;
            this.saveStateToLocalStorage();
        },
        setOrganicTypeSerpBeaterCompetitors(organicTypeSerpBeaterCompetitors) {
            this.organicTypeSerpBeaterCompetitors =
                organicTypeSerpBeaterCompetitors;
            this.saveStateToLocalStorage();
        },
        setSelectedSerpBeaterCompetitorsId(selectedSerpBeaterCompetitorsId) {
            this.selectedSerpBeaterCompetitorsId =
                selectedSerpBeaterCompetitorsId;
            this.saveStateToLocalStorage();
        },
        setArticleTitle(value) {
            this.setPreviousArticleTitle(this.articleTitle);
            this.articleTitle = value;
            this.saveStateToLocalStorage();
        },
        setPreviousArticleTitle(value) {
            this.previousArticleTitle = value;
            this.saveStateToLocalStorage();
        },
        setAiGeneratedTitles(aiGeneratedTitles) {
            this.aiGeneratedTitles = aiGeneratedTitles;
            this.saveStateToLocalStorage();
        },
        setArticleTitleDragging(value) {
            this.articleTitleDragging = value;
            this.saveStateToLocalStorage();
        },
        setArticleOutline(value) {
            this.articleOutline = value;
            this.saveStateToLocalStorage();
        },
        setHeadingLists(value) {
            this.headingLists = value;
            this.saveStateToLocalStorage();
        },
        setCurrentH2Id(value) {
            this.currentH2Id = value;
            this.saveStateToLocalStorage();
        },
        setSelectedFaqList(value) {
            this.selectedFaqList = value;
        },
        addToAiGeneratedOutlines(value) {
            this.aiGeneratedOutlines.push(value);
            this.saveStateToLocalStorage();
        },
        addToH2HeadingList(heading, removeDuplicate = true) {
            if (!removeDuplicate) {
                this.headingLists.push(heading);

                return false;
            }

            if (
                0 ===
                this.headingLists.filter(
                    (headingList) => headingList.id == heading.id
                ).length
            ) {
                this.headingLists.push(heading);
            }

            this.saveStateToLocalStorage();
        },
        addToH3HeadingList(h2HeadingId, heading, removeDuplicate = true) {
            const filteredObject = this.headingLists.find(
                (headingList) => headingList.id === h2HeadingId
            );

            if (!filteredObject) {
                return false;
            }

            if (!removeDuplicate) {
                filteredObject.subHeadings.push(heading);

                return false;
            }

            if (
                0 ===
                filteredObject.subHeadings.filter(
                    (subHeading) => subHeading.id == heading.id
                ).length
            ) {
                filteredObject.subHeadings.push(heading);
            }

            this.saveStateToLocalStorage();
        },
        addToSelectedFaqList(faq, removeDuplicate = true) {
            if (!removeDuplicate) {
                this.selectedFaqList.push(faq);

                return false;
            }

            if (
                0 ===
                this.selectedFaqList.filter(
                    (selectedFaq) => selectedFaq.id == faq.id
                ).length
            ) {
                this.selectedFaqList.push(faq);
            }

            this.saveStateToLocalStorage();
        },
        removeFromSelectedFaqListById(faqId) {
            this.selectedFaqList = this.selectedFaqList.filter(
                (selectedFaq) => selectedFaq.id !== faqId
            );
            this.saveStateToLocalStorage();
        },
        setAiGeneratedOutlines(value) {
            this.aiGeneratedOutlines = value;
            this.saveStateToLocalStorage();
        },
        setTopRankedOutlines(topRankedOutlines) {
            try {
                if (!topRankedOutlines) {
                    this.topRankedOutlines = [];
                    return;
                }

                // Handle both array and object with value property
                const outlines = Array.isArray(topRankedOutlines)
                    ? topRankedOutlines
                    : (topRankedOutlines.value || []);

                // Filter out any null/undefined items and ensure each item has required properties
                this.topRankedOutlines = outlines
                    .filter(outline => outline && typeof outline === 'object')
                    .map(outline => ({
                        id: outline.id || null,
                        rank: outline.rank || 0,
                        isLoading: false,
                        data: outline.data || null,
                        error: false
                    }));

                this.saveStateToLocalStorage();
            } catch (error) {
                console.error('Error in setTopRankedOutlines:', error);
                this.topRankedOutlines = [];
            }
        },
        setTopRankedOutlineFaqs(topRankedOutlineFaqs) {
            this.topRankedOutlineFaqs = topRankedOutlineFaqs;
            this.saveStateToLocalStorage();
        },
        setAiGeneratedH2Ideas(value) {
            this.aiGeneratedH2Ideas = value;
            this.saveStateToLocalStorage();
        },
        setAiGeneratedH3Ideas(h3Ideas, headingListIndex) {
            this.aiGeneratedH3Ideas[headingListIndex] = h3Ideas;
        },
        removeAiGeneratedH3Ideas(headingListIndex) {
            delete this.aiGeneratedH3Ideas[headingListIndex];
            this.saveStateToLocalStorage();
        },
        setAiGeneratedFaqIdeas(value) {
            this.aiGeneratedFaqIdeas = value;
            this.saveStateToLocalStorage();
        },
        setArticleOutlineRequestPayload(value) {
            this.articleOutlineRequestPayload = value;
            this.saveStateToLocalStorage();
        },
        setAiGeneratedWritingPoints(writingPoints) {
            this.aiGeneratedWritingPoints =
                this.aiGeneratedWritingPoints.concat(
                    this.getWritingPointByType(writingPoints, 'heading_lists'),
                    this.getWritingPointByType(writingPoints, 'faq_lists')
                );

            this.saveStateToLocalStorage();
        },
        resetAiGeneratedWritingPoints() {
            this.aiGeneratedWritingPoints = [];

            this.saveStateToLocalStorage();
        },
        getWritingPointByType(writingPoints, writingPointType) {
            return (
                writingPoints[writingPointType]
                    ?.filter(
                        (writingPoints) =>
                            writingPoints !== null &&
                            writingPoints !== undefined
                    )
                    .map((writingPoints) => {
                        return {
                            ...writingPoints,
                            type: writingPointType,
                        };
                    }) || []
            );
        },
        addToAiGeneratedWritingPoints(value, h2Index, h3Index = null) {
            if (null !== h3Index) {
                let targetKey = Object.keys(
                    this.aiGeneratedWritingPoints[h2Index].heading_type_3[
                        h3Index
                        ]
                )[0];
                this.aiGeneratedWritingPoints[h2Index].heading_type_3[h3Index][
                    targetKey
                    ].push(value);
            } else {
                let targetKey = Object.keys(
                    this.aiGeneratedWritingPoints[h2Index].heading_type_2
                )[0];
                this.aiGeneratedWritingPoints[h2Index].heading_type_2[
                    targetKey
                    ].push(value);
            }

            this.saveStateToLocalStorage();
        },
        updateAiGeneratedWritingPoints(
            newValue,
            pointIndex,
            h2Index,
            h3Index = null
        ) {
            if (null !== h3Index) {
                let targetKey = Object.keys(
                    this.aiGeneratedWritingPoints[h2Index].heading_type_3[
                        h3Index
                        ]
                )[0];
                this.aiGeneratedWritingPoints[h2Index].heading_type_3[h3Index][
                    targetKey
                    ][pointIndex] = newValue;
            } else {
                let targetKey = Object.keys(
                    this.aiGeneratedWritingPoints[h2Index].heading_type_2
                )[0];
                this.aiGeneratedWritingPoints[h2Index].heading_type_2[
                    targetKey
                    ][pointIndex] = newValue;
            }

            this.saveStateToLocalStorage();
        },
        removeFromAiGeneratedWritingPoints(
            pointIndex,
            h2Index,
            h3Index = null
        ) {
            if (null !== h3Index) {
                let targetKey = Object.keys(
                    this.aiGeneratedWritingPoints[h2Index].heading_type_3[
                        h3Index
                        ]
                )[0];
                this.aiGeneratedWritingPoints[h2Index].heading_type_3[h3Index][
                    targetKey
                    ].splice(pointIndex, 1);
            } else {
                let targetKey = Object.keys(
                    this.aiGeneratedWritingPoints[h2Index].heading_type_2
                )[0];
                this.aiGeneratedWritingPoints[h2Index].heading_type_2[
                    targetKey
                    ].splice(pointIndex, 1);
            }

            this.saveStateToLocalStorage();
        },
        addOrUpdateAiGeneratedWritingPoints(writingPoints, h2Index, h3Index = null) {
            if (null !== h3Index) {
                let targetKey = Object.keys(
                    this.aiGeneratedWritingPoints[h2Index].heading_type_3[
                        h3Index
                        ]
                )[0];
                this.aiGeneratedWritingPoints[h2Index].heading_type_3[h3Index][
                    targetKey
                    ] = writingPoints;
            } else {
                let targetKey = Object.keys(
                    this.aiGeneratedWritingPoints[h2Index].heading_type_2
                )[0];
                this.aiGeneratedWritingPoints[h2Index].heading_type_2[
                    targetKey
                    ] = writingPoints;
            }

            this.saveStateToLocalStorage();
        },
        setEmptyWritingPoints() {
            this.aiGeneratedWritingPoints = [];
        },
        addToArticleDraftContent(value, type, h2Index = null, h3Index = null) {
            if (null !== h2Index && null == h3Index) {
                let headingObject = {
                    heading: value.heading,
                    content: value.content,
                    sub_headings: value.sub_headings || [],
                    paragraph_length: value.paragraph_length || '100-150',
                    num_sections: value.num_sections || 1,
                    content_type: value.content_type || 'paragraph',
                    primary_keyword: value.primary_keyword || '',
                    secondary_keywords: value.secondary_keywords || [],
                };
                this.articleDraftContent[type].push(headingObject);
            } else {
                let subHeadingObject = {
                    heading: value.heading,
                    content: value.content,
                    sub_headings: value.sub_headings || [],
                    paragraph_length: value.paragraph_length || '100-150',
                    num_sections: value.num_sections || 1,
                    content_type: value.content_type || 'paragraph',
                    primary_keyword: value.primary_keyword || '',
                    secondary_keywords: value.secondary_keywords || [],
                };
                this.articleDraftContent[type][h2Index].sub_headings.push(
                    subHeadingObject
                );
            }

            this.saveStateToLocalStorage();
        },
        updateArticleDraftContent(value, type, h2Index = null, h3Index = null) {
            if (null !== h2Index && null == h3Index) {
                this.articleDraftContent[type][h2Index] = {
                    heading: value.heading,
                    content: value.content,
                    sub_headings: value.sub_headings || [],
                    paragraph_length: value.paragraph_length || '100-150',
                    num_sections: value.num_sections || 1,
                    content_type: value.content_type || 'paragraph',
                    primary_keyword: value.primary_keyword || '',
                    secondary_keywords: value.secondary_keywords || [],
                };
            } else {
                this.articleDraftContent[type][h2Index].sub_headings[h3Index] = {
                    heading: value.heading,
                    content: value.content,
                    sub_headings: value.sub_headings || [],
                    paragraph_length: value.paragraph_length || '100-150',
                    num_sections: value.num_sections || 1,
                    content_type: value.content_type || 'paragraph',
                    primary_keyword: value.primary_keyword || '',
                    secondary_keywords: value.secondary_keywords || [],
                };
            }

            this.saveStateToLocalStorage();
        },
        clearArticleDraftContentSection(type = null) {
            if (type) {
                this.articleDraftContent[type] = [];
            } else {
                this.articleDraftContent = {
                    introduction: [],
                    heading_lists: [],
                    faq_lists: [],
                    conclusion: [],
                };
            }
            this.saveStateToLocalStorage();
        },
        addArticleDraftPreviousContent(value) {
            this.articleDraftPreviousContent.push(value);
            this.saveStateToLocalStorage();
        },
        removeArticleDraftPreviousContent(index) {
            this.articleDraftPreviousContent.splice(index, 1);
            this.saveStateToLocalStorage();
        },
        setCurrentStep(value) {
            this.currentStep = value;
            this.saveStateToLocalStorage();
        },
        setFinishedStep(value) {
            this.finishedStep = value;
            this.saveStateToLocalStorage();
        },
        setIsError(value) {
            this.isError = value;
            this.saveStateToLocalStorage();
        },
        resetState() {
            let articleWizardType = this.getArticleWizardType;
            //don't save the article wizard type to the local storage. It may cause multiple unwanted API calls.
            this.setArticleWizardType('');

            Object.assign(this.$state, getDefaultState());
            this.clearSavedState();

            //Restore old state.
            this.setArticleWizardType(articleWizardType);
        },
    },
});
