<template>
    <div>
        <Head :title="title" />

        <Impersonate />
        <jet-banner />

        <div
            :class="{ 'menu-expand': isMenuExpanded }"
            class="page d-flex flex-row">
            <Aside :user="user"></Aside>

            <div
                :class="wrapperClass"
                class="d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
            >
                <!--begin::Header-->
                <Header :user="user" :title="title" :view="view"></Header>
                <!--end::Header-->

                <div
                    id="kt_content"
                    class="content d-flex flex-row align-items-stretch flex-grow-1"
                    :class="contentClasses"
                >
                    <slot name="toolbar"></slot>

                    <div class="post d-lg-flex flex-column flex-grow-1">
                        <!-- Page Content -->
                        <main :class="mainClass" class="flex-grow-1">
                            <slot></slot>
                        </main>
                    </div>
                </div>
            </div>
        </div>

        <AiAssistantModal :user="user"></AiAssistantModal>
        <ArticleWizardModal
            ref=""
            :user="user"
            v-if="$page.component == 'Wizard/Selection'"
        ></ArticleWizardModal>
        <OneClickArticleGenerationModal
            :user="user"
            v-if="$page.component == 'Wizard/Selection'"
        ></OneClickArticleGenerationModal>
        <GenerateArticleFromUrlWizardModal
            ref=""
            :user="user"
            v-if="$page.component == 'Wizard/Selection'"
        ></GenerateArticleFromUrlWizardModal>
        <ImageGeneratorModal :user="user"></ImageGeneratorModal>
        <ChoosePlanModal :user="user"></ChoosePlanModal>
        <BuyMoreCharacterCreditsModal
            :user="user"
        ></BuyMoreCharacterCreditsModal>
        <BuyMoreKeywordResearchCreditsModal
            :user="user"
        ></BuyMoreKeywordResearchCreditsModal>
        <BuyMoreSerpBeaterCreditsModal
            :user="user"
            v-if="$page.component == 'SerpBeater/List'"
        ></BuyMoreSerpBeaterCreditsModal>
        <BuyMoreImageCreditsModal
            :user="user"
            v-if="$page.component == 'ImageGenerator/List'"
        ></BuyMoreImageCreditsModal>
        <AddImageGeneratorToPlanModal
            :user="user"
        ></AddImageGeneratorToPlanModal>
        <SerpBeaterWizardModal :user="user"></SerpBeaterWizardModal>
        <NewSerpBeaterModal :user="user"></NewSerpBeaterModal>
        <NewKeywordResearchModal :user="user"></NewKeywordResearchModal>
        <NewProjectModal :user="user"></NewProjectModal>
        <ProjectsModal :user="user"></ProjectsModal>
        <DeleteModal></DeleteModal>
        <NotificationDetailModal
            :user="user"
            :notificationDetailModalData="notificationDetailModalData"
        ></NotificationDetailModal>
        <div
            v-if="drawerOverlay"
            @click="closeAsideMobileMenu"
            class="drawer-overlay"
        ></div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { useClipboard } from '@/composables/useClipboard';
import Impersonate from '@/Components/Impersonate/Impersonate.vue';
import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue';
import JetBanner from '@/Jetstream/Banner.vue';
import JetApplicationMark from '@/Jetstream/ApplicationMark.vue';
import JetDropdown from '@/Jetstream/Dropdown.vue';
import JetDropdownLink from '@/Jetstream/DropdownLink.vue';
import JetNavLink from '@/Jetstream/NavLink.vue';
import { Head, Link } from '@inertiajs/vue3';
import Aside from '@/Layouts/Aside/Aside.vue';
import Header from '@/Layouts/Header/Header.vue';
import AiAssistantModal from '@/Modals/AiAssistantModal.vue';
import ArticleWizardModal from '@/Modals/ArticleWizardModal.vue';
import OneClickArticleGenerationModal from '@/Modals/OneClickArticleGenerationModal.vue';
import GenerateArticleFromUrlWizardModal from '@/Modals/GenerateArticleFromUrlWizardModal.vue';
import ImageGeneratorModal from '@/Modals/ImageGeneratorModal.vue';
import ChoosePlanModal from '@/Modals/ChoosePlanModal.vue';
import BuyMoreCharacterCreditsModal from '@/Modals/BuyMoreCharacterCreditsModal.vue';
import BuyMoreKeywordResearchCreditsModal from '@/Modals/BuyMoreKeywordResearchCreditsModal.vue';
import BuyMoreSerpBeaterCreditsModal from '@/Modals/BuyMoreSerpBeaterCreditsModal.vue';
import BuyMoreImageCreditsModal from '@/Modals/BuyMoreImageCreditsModal.vue';
import AddImageGeneratorToPlanModal from '@/Modals/AddImageGeneratorToPlanModal.vue';
import SerpBeaterWizardModal from '@/Modals/SerpBeaterWizardModal.vue';
import NewSerpBeaterModal from '@/Modals/NewSerpBeaterModal.vue';
import NewProjectModal from '@/Modals/NewProjectModal.vue';
import ProjectsModal from '@/Modals/ProjectsModal.vue';
import DeleteModal from '@/Modals/DeleteModal.vue';
import NotificationDetailModal from '@/Modals/NotificationDetailModal.vue';
import NewKeywordResearchModal from '@/Modals/NewKeywordResearchModal.vue';
import { Modal } from 'bootstrap';
import moment from 'moment';

export default defineComponent({
    setup() {
        // Get toast interface
        const toast = useToast();
        const { copy: toClipboard } = useClipboard();

        const copy = async (text) => {
            try {
                await toClipboard(text);
            } catch (e) {
                console.error(e);
            }
        };
        // Make it available inside methods
        return { toast, copy };
    },
    props: {
        title: String,
        view: String,
        user: Object,
        wrapperClass: {
            type: String,
            default: 'wrapper',
        },
        mainClass: {
            type: String,
            default: 'container my-5',
        },
        ktToolbarHeight: {
            type: String,
            default: '65px',
        },
        ktToolbarHeightTabletAndMobile: {
            type: String,
            default: '65px',
        },
    },

    components: {
        Impersonate,
        Header,
        Head,
        JetApplicationLogo,
        JetBanner,
        JetApplicationMark,
        JetDropdown,
        JetDropdownLink,
        JetNavLink,
        Link,
        Aside,
        AiAssistantModal,
        ArticleWizardModal,
        OneClickArticleGenerationModal,
        GenerateArticleFromUrlWizardModal,
        ImageGeneratorModal,
        ChoosePlanModal,
        BuyMoreCharacterCreditsModal,
        BuyMoreKeywordResearchCreditsModal,
        BuyMoreSerpBeaterCreditsModal,
        BuyMoreImageCreditsModal,
        AddImageGeneratorToPlanModal,
        SerpBeaterWizardModal,
        NewSerpBeaterModal,
        NewProjectModal,
        ProjectsModal,
        DeleteModal,
        NotificationDetailModal,
        NewKeywordResearchModal,
    },

    data() {
        return {
            showingNavigationDropdown: false,
            drawerOverlay: false,
            promoDuration: 0,
            contentClasses: '',
            notificationDetailModal: {},
            notificationDetailModalData: {},
            isMenuExpanded: true,
        };
    },

    watch: {
        isMenuExpanded(newVal) {
            if (newVal) {
                document.body.setAttribute('data-kt-aside-minimize', 'off');
            } else {
                document.body.setAttribute('data-kt-aside-minimize', 'on');
            }
        },
    },

    mounted() {
        this.$gtm.trackEvent({
            event: 'start_pw',
            pw_user_email: this.user.email,
        });

        this.$posthog.identify(this.user.id.toString(), {
            email: this.user.email,
            name: this.user.name,
        });

        Intercom('boot', {
            app_id: INTERCOM_APP_ID,
            user_id: this.user.id,
            name: this.user.name,
            email: this.user.email,
            user_hash: this.user.intercom_hash,
            signup_time: Math.floor(Date.now() / 1000),
            subscription_customer:
                this.user.current_team.subscriptions.length > 0,
            subscription_plan:
                this.user.current_team.subscriptions.length > 0
                    ? this.getPlanNames(
                          this.user.current_team.subscriptions[0].paddle_plan
                      )
                    : null,
            appsumo_ltd_customer:
                this.user.current_team.lifetime_subscriptions.length > 0,
            appsumo_tier: this.user.current_team.lifetime_subscriptions.length,
            subscription_paused_from:
                this.user.current_team.subscriptions.length > 0
                    ? this.user.current_team.subscriptions[0].paused_from
                    : null,
            subscription_ends_at:
                this.user.current_team.subscriptions.length > 0
                    ? this.user.current_team.subscriptions[0].ends_at
                    : null,
            user_created_at: this.user.created_at,
            created_at: this.user.created_at,
        });

        let toolbarHeight = this.ktToolbarHeight;
        let toolbarHeightMobile = this.ktToolbarHeightTabletAndMobile;
        let smartBarCookie = this.getCookie('smart-bar');

        if (smartBarCookie === 'true') {
            toolbarHeight = '145px';
            toolbarHeightMobile = '135px';
            this.contentClasses = 'smart-bar-active';
        }

        document.body.style.setProperty('--kt-toolbar-height', toolbarHeight);
        document.body.style.setProperty(
            '--kt-toolbar-height-tablet-and-mobile',
            toolbarHeightMobile
        );

        let vm = this;
        emitter.on('open-aside-mobile-menu', function () {
            vm.drawerOverlay = true;
        });

        emitter.on('close-aside-mobile-menu', function () {
            vm.drawerOverlay = false;
        });

        emitter.on('close-smart-bar', function () {
            document.body.style.setProperty(
                '--kt-toolbar-height',
                vm.ktToolbarHeight
            );
            document.body.style.setProperty(
                '--kt-toolbar-height-tablet-and-mobile',
                vm.ktToolbarHeightTabletAndMobile
            );
            vm.contentClasses = '';
        });

        emitter.on('clipboard', function (text) {
            vm.copy(text);
        });

        this.notificationDetailModal = new Modal(
            document.getElementById('kt_modal_notification_detail_modal')
        );
        emitter.on('open-notification-detail-modal', function (notificationId) {
            vm.notificationDetailModal.show();
            vm.notificationDetailModalData = {
                notification_id: notificationId,
            };
        });
        emitter.on('menu-toggle-clicked', function () {
            vm.isMenuExpanded = !vm.isMenuExpanded;
        });
    },

    beforeUnmount() {
        emitter.off('open-aside-mobile-menu');
        emitter.off('close-aside-mobile-menu');
        emitter.off('close-smart-bar');
        emitter.off('clipboard');
        emitter.off('open-notification-detail-modal');
    },

    methods: {
        switchToTeam(team) {
            this.$inertia.put(
                route('current-team.update'),
                {
                    team_id: team.id,
                },
                {
                    preserveState: false,
                }
            );
        },

        logout() {
            this.$inertia.post(route('logout'));
        },

        closeAsideMobileMenu() {
            this.drawerOverlay = false;
            emitter.emit('close-aside-mobile-menu');
        },

        getCookie(c_name) {
            if (document.cookie.length > 0) {
                let c_start = document.cookie.indexOf(c_name + '=');
                if (c_start != -1) {
                    c_start = c_start + c_name.length + 1;
                    let c_end = document.cookie.indexOf(';', c_start);
                    if (c_end == -1) {
                        c_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return null;
        },
    },

    computed: {
        path() {
            return window.location.pathname;
        },
    },
});
</script>

<style lang="scss">
.drawer-overlay {
    z-index: 1001;
}
</style>
