<template>
    <app-layout
        title="Chat"
        :view="view"
        :user="$attrs.user"
        wrapper-class="wrapper-fullscreen"
        main-class="main-fullscreen"
        kt-toolbar-height="1px"
        kt-toolbar-height-tablet-and-mobile="1px"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Chat') }}
            </h1>
        </template>

        <div class="d-flex flex-column flex-grow-1">
            <div class="d-flex flex-grow-1 flex-column flex-md-row">
                <!--begin::Sidebar-->
                <div class="chat-sidebar flex-shrink-0 w-100 w-md-300px w-lg-300px w-xl-400px mb-4 mb-md-0">
                    <!--begin::Contacts-->
                    <div class="h-100">
                        <div class="p-3 p-md-5">
                            <SearchForm
                                @search-key-up="
                                    searchChatGptConversation($event)
                                "
                            ></SearchForm>
                        </div>

                        <div class="p-3 p-md-5">
                            <!--begin::List-->
                            <div
                                class="chat-list me-n2 me-md-n5 pe-2 pe-md-5"
                                @scroll="chatHistoryScrollEvent"
                            >
                                <ChatList
                                    :chatGptConversation="chatGptConversation"
                                    v-for="chatGptConversation in oldChatGptConversations"
                                    :key="chatGptConversation.uuid"
                                    :current-chat-uuid="chatUuID"
                                    @update-chat-name="updateChatName"
                                    @single-chat-deleted="fetchChatList"
                                    @single-chat-selected="
                                        fetchSingleChat($event, uuid)
                                    "
                                ></ChatList>

                                <div v-if="isLoadingChatList" class="my-2">
                                    <div
                                        class="d-flex align-items-center justify-content-center p-5"
                                    >
                                        <div
                                            role="status"
                                        >
                                            <IconLoading class="animate-spin" />
                                            <span class="visually-hidden">{{
                                                __('Loading...')
                                            }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-else-if="
                                        0 === oldChatGptConversations.length &&
                                        !isLoadingChatList
                                    "
                                >
                                    {{ __('No chat history found!') }}
                                </div>
                            </div>
                            <!--end::List-->
                        </div>
                        <!--end::Card body-->
                    </div>
                    <!--end::Contacts-->
                </div>
                <!--end::Sidebar-->
                <div class="chat-content flex-grow-1 w-75">
                    <!--begin::Messenger-->
                    <div
                        class="d-flex bg-white flex-column justify-content-between h-100"
                    >
                        <div
                            class="d-flex justify-content-between border-bottom px-3 px-md-10 py-2 py-md-3"
                        >
                            <!--begin::Title-->
                            <div class="card-title p-4"></div>
                            <!--end::Title-->

                            <!--begin::Card toolbar-->
                            <div class="card-toolbar">
                                <button
                                    class="btn btn-sm btn-primary"
                                    @click="reloadPage()"
                                >
                                    {{ __('New Chat') }}
                                </button>
                            </div>
                            <!--end::Card toolbar-->
                        </div>

                        <!--begin::Card body-->
                        <div
                            ref="chatContainer"
                            class="scroll-y card-body chats mb-3 mb-md-5 p-2 p-md-5"
                            id="chat-messenger-body"
                        >
                            <div class="flex" v-if="wrapper.length">
                                <!--begin::Messages-->
                                <div
                                    class="mx-auto w-100 chat-entries"
                                >
                                    <ChatMessage
                                        v-for="(chat, i) in wrapper"
                                        :chat-uuid="chatUuID"
                                        :chat="chat"
                                        :key="i"
                                        :index="Number(i)"
                                        :user="$attrs.user"
                                        :isStreaming="isStreaming"
                                        :isLastMessage="Number(i) === wrapper.length - 1"
                                        @update-scroll-height="updateScrollHeight"
                                    />
                                </div>
                                <!--end::Messages-->
                            </div>
                            <div
                                v-else-if="isLoading"
                                class="d-flex align-items-center justify-content-center h-100 p-5 pb-20"
                            >
                                <div role="status">
                                    <IconLoading class="animate-spin" />
                                    <span class="visually-hidden">{{
                                        __('Loading...')
                                    }}</span>
                                </div>
                            </div>
                            <div class="mx-2 mx-md-10 p-2" v-else>
                                <ChatIntroScreen
                                    @single-example-chat-selected="exampleChat = $event"
                                >
                                </ChatIntroScreen>
                            </div>
                            <!--end::Card body-->
                        </div>

                        <!--begin::Card footer-->
                        <div
                            class="chat-form position-relative py-5"
                            id="kt_chat_messenger_footer"
                        >
                            <ChatForm
                                :user="$attrs.user"
                                :page="$page"
                                :chatUuID="chatUuID"
                                :exampleChat="exampleChat"
                                :is-streaming="isStreaming"
                                @streaming-started="handleStreamingStarted"
                                @streaming-stopped="handleStreamingStopped"
                                @send-button-triggered="
                                    sendButtonTriggered($event)
                                "
                                :previousQuestion="previousQuestion"
                                @update-chat-uuid="updateChatUuid"
                                @got-chat-response-error="
                                    handleResponseError($event)
                                "
                                @chat-regenerate-response="regenerateResponse"
                                @on-streaming="appendMessageToChat"
                            ></ChatForm>
                        </div>
                        <!--end::Card footer-->
                    </div>
                    <!--end::Messenger-->
                </div>
            </div>
        </div>
        <div id="chat-gpt-live-search-result">
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import CreaitorLoading from '@/Components/Loading/CreaitorLoading.vue';
import SearchForm from '@/Layouts/AiChat/SearchForm.vue';
import ChatList from '@/Layouts/AiChat/ChatList.vue';
import ChatMessage from '@/Layouts/AiChat/ChatMessage.vue';
import ChatForm from '@/Layouts/AiChat/ChatForm.vue';
import ChatIntroScreen from '@/Layouts/AiChat/ChatIntroScreen.vue';
import { router as Inertia } from '@inertiajs/vue3';
import hljs from 'highlight.js';
import MarkdownIt from 'markdown-it';
import IconLoading from '@/icons/loading.svg?component';

export default {
    components: {
        AppLayout,
        CreaitorLoading,
        SearchForm,
        ChatList,
        ChatMessage,
        ChatForm,
        ChatIntroScreen,
        IconLoading,
    },

    data() {
        return {
            view: this.__('view'),
            oldChatGptConversations: [],
            wrapper: [],
            chatUuID: '',
            nextPageUrl: '',
            isLoading: false,
            isLoadingChatList: false,
            exampleChat: '',
            previousQuestion: '',
            autoScrollEnabled: true,
            isStreaming: false,
        };
    },
    mounted() {
        document.body.classList.add('custom-intercom');
        this.fetchChatList();

        if (this.$attrs.conversation?.uuid) {
            this.fetchSingleChat(this.$attrs.conversation.uuid);
        }

        this.$nextTick(() => {
            if (this.$refs.chatContainer) {
                this.$refs.chatContainer.addEventListener(
                    'scroll',
                    this.handleScroll
                );
            } else {
                console.error('Chat container ref not found');
            }
        });

        emitter.on('reload-chat-list', this.fetchChatList);
    },
    methods: {
        fetchChatList(filter = {}) {
            let vm = this;
            let requestUrl = `/api/chat-gpt-conversation`;
            this.isLoadingChatList = true;

            axios
                .get(requestUrl, {
                    params: filter,
                })
                .then((response) => {
                    vm.nextPageUrl = response.data.next_page_url;
                    vm.oldChatGptConversations = response.data.data;
                    vm.isLoadingChatList = false;
                });
        },

        appendMessageToChat(message) {
            // Check if the last message is from AI
            if (this.wrapper.length > 0 && this.wrapper[this.wrapper.length - 1].isAi) {
                let lastMessage = this.wrapper[this.wrapper.length - 1];
                lastMessage.response += message.content;
                lastMessage.isLoading = false;
                lastMessage.messageId = message.messageId;
                lastMessage.creationId = message.creationId;

                if (message.aiToolResults) {
                    lastMessage.aiToolResults = this.processAiToolResults(message.aiToolResults);
                }
            } else {
                this.wrapper.push({
                    isAi: true,
                    isLoading: false,
                    response: message.content,
                    messageId: message.messageId,
                    creationId: message.creationId,
                });
            }

            if (message.isFinalMessage) {
                this.isStreaming = false;
            }

            // If autoscroll is enabled, scroll to the bottom of the chat
            if (this.autoScrollEnabled) {
                this.$nextTick(() => {
                    this.scrollToBottom();
                });
            }
        },

        processAiToolResults(aiToolResults) {
            try {
                return JSON.parse(aiToolResults);
            } catch (error) {
                return [];
            }
        },

        scrollToBottom() {
            if (!this.autoScrollEnabled) return;

            const chatContainer = this.$refs.chatContainer;
            if (chatContainer) {
                chatContainer.scrollTop = chatContainer.scrollHeight;
            }
        },

        handleScroll() {
            const chatContainer = this.$refs.chatContainer;
            if (!chatContainer) return;

            // Check if the user is near the bottom (within 50 pixels)
            const isNearBottom =
                chatContainer.scrollTop + chatContainer.clientHeight >=
                chatContainer.scrollHeight - 100;
            this.autoScrollEnabled = isNearBottom;
        },

        chatHistoryScrollEvent({
            target: { scrollTop, clientHeight, scrollHeight },
        }) {
            let vm = this;
            if (!this.nextPageUrl) {
                return false;
            }

            if (scrollTop + clientHeight >= scrollHeight - 1) {
                axios.get(this.nextPageUrl).then((response) => {
                    vm.nextPageUrl = response.data.next_page_url;
                    let tempOldChatGptConversations =
                        vm.oldChatGptConversations.concat(response.data.data);
                    vm.oldChatGptConversations = tempOldChatGptConversations;
                });
            }
        },

        sendButtonTriggered(data) {
            if (0 === this.wrapper.length) {
                this.oldChatGptConversations.unshift({
                    uuid: '',
                    name: this.__('New conversation'),
                    created_at: '',
                    disable_delete_button: true,
                });
            }

            this.wrapper.push({
                isAi: false,
                response: data.question,
                isOldChat: false,
            });
            this.wrapper.push({
                isAi: true,
                isLoading: true,
                originalResponse: '',
                response: '',
                isOldChat: false,
            });
        },

        handleStreamingStarted() {
            this.isStreaming = true;
        },

        handleStreamingStopped() {
            this.isStreaming = false;
        },

        updateChatUuid(chatUuid) {
            this.chatUuID = chatUuid;
            this.updateUrlWithUuid(chatUuid);
            this.fetchChatList();
        },

        updateUrlWithUuid(newUuid) {
            // Define the base path for the chat
            const basePath = '/chat-gpt';
            let newPath = '';

            // Check if the current URL contains the base path
            if (window.location.pathname.includes(basePath)) {
                // Split the URL by the base path
                const pathParts = window.location.pathname.split(basePath);

                // Check if there's a segment after the base path
                if (pathParts.length > 1 && pathParts[1]) {
                    // Replace the segment after the base path with the new UUID
                    newPath = basePath + '/' + newUuid;
                } else {
                    // If no segment after the base path, just append the new UUID
                    newPath = window.location.pathname + '/' + newUuid;
                }
            } else {
                // If the base path isn't in the URL, log an error or handle as needed
                console.error('Base path not found in the current URL');
                return;
            }

            // Update the URL
            window.history.pushState({}, document.title, newPath);
        },

        updateScrollHeight() {
            let scroll = this.$refs.chatContainer;
            scroll.scrollTop = parseInt(scroll.scrollHeight);
        },

        updateChatName(uuid, name) {
            let vm = this;
            let requestUrl = `/api/chat-gpt-conversation/${uuid}`;
            let requestParams = {
                name: name,
            };

            axios.put(requestUrl, requestParams).then((response) => {
                vm.fetchChatList();
            });
        },

        reloadPage() {
            Inertia.visit('/chat-gpt');
        },

        fetchSingleChat(uuid) {
            let vm = this;
            let requestUrl = `/api/chat-gpt-conversation/${uuid}`;
            vm.isLoading = true;
            this.wrapper = [];
            this.chatUuID = uuid;

            this.updateUrlWithUuid(uuid);

            axios.get(requestUrl).then((response) => {
                vm.wrapper = response.data.messages.map((conversation) => {
                    return {
                        isAi: !conversation.user_id,
                        response: conversation.content,
                        isOldChat: true,
                        aiToolResults: conversation.ai_tool_results,
                    };
                });
                vm.isLoading = false;
            });
        },

        searchChatGptConversation(searchKey) {
            this.fetchChatList({ name: searchKey });
        },

        regenerateResponse() {
            this.previousQuestion =
                this.wrapper[this.wrapper.length - 2].response;
            this.wrapper.splice(-2, 2);
        },

        handleResponseError() {
            this.wrapper.splice(-2);
        },
    },
    beforeUnmount() {
        const chatContainer = this.$refs.chatContainer;
        if (chatContainer) {
            chatContainer.removeEventListener('scroll', this.handleScroll);
        }
    },
};
</script>

<style lang="scss">
.main-fullscreen {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #f4f7f9;
}

.custom-intercom .intercom-lightweight-app-launcher {
    bottom: 100px;
}

.home-link {
    color: #a1a5b7;
}

.chat-sidebar {
    background-color: #fff;
    border-right: 1px solid #eee;
    overflow-y: auto;
    height: calc(100vh - 70px);

    @media (max-width: 767px) {
        height: 300px;
        border-right: none;
        border-bottom: 1px solid #eee;
    }
}

.chat-content {
    height: calc(100vh - 70px);

    @media (max-width: 767px) {
        height: calc(100vh - 370px);
    }
}

.chat-entries {
    max-width: 800px;
    margin: 0 auto;
}

.chat-form {
    padding: 1rem;
    border-top: 1px solid #eee;
    background-color: #fff;

    @media (max-width: 767px) {
        padding: 0.5rem;
    }
}
</style>
