import { mergeAttributes, Mark } from '@tiptap/core';
export const AutoSuggestionText = Mark.create({
    name: 'autoSuggestionText',
    priority: 1000,
    addOptions() {
        return {
            HTMLAttributes: {
                class: 'suggestion', // add this line
            },
        };
    },
    parseHTML() {
        return [
            { tag: 'span.suggestion' }, // adjust the tag here
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return [
            'span',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            ['span', { class: 'suggestion-text text-gray-400' }, this.options.text],
        ];
    },
});
export default AutoSuggestionText;
