<template>
    <div v-if="editorStore.getEditor" class="editor d-flex flex-column">
        <EditorBubbleMenu @compose="compose" />
        <ImageBubbleMenu />
        <floating-menu
            :editor="editorStore.getEditor"
            :tippy-options="{ duration: 100, zIndex: 999 }"
            v-if="editorStore.getEditor"
        >
            <div class="btn-group" role="group">
                <button
                    id="btn-text-group"
                    type="button"
                    class="btn dropdown-toggle d-flex border-0 bg-transparent align-items-center p-0"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <div class="remix w-100 h-100">
                        <i
                            :class="`!text-xl ri-edit-2-line`"
                        />
                    </div>
                </button>
                <ul
                    class="dropdown-menu !top-2"
                    aria-labelledby="btn-text-group"
                >
                    <li>
                        <a
                            class="dropdown-item d-flex"
                            href="#"
                            @click.prevent="
                                                            compose(
                                                                'continue',
                                                                { length: 's' }
                                                            );
                                                            forceHideFloatigMenu = true;
                                                        "
                        >
                                                        <span
                                                            class="d-flex d-flex icon border rounded"
                                                        >
                                                            <svg
                                                                class="w-100 h-100 p-1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 384 512"
                                                            >
                                                                <path
                                                                    d="M349.9 382.7c-6.109 34.88-24.91 61.98-54.34 78.39c-24.69 13.77-54.23 18.84-84.38 18.84c-67.36 0-137.8-25.33-164.1-35.86c-12.31-4.922-18.3-18.89-13.38-31.19c4.938-12.31 18.91-18.28 31.19-13.38c87.11 34.83 166.6 42.34 207.3 19.66c16.86-9.391 26.81-24.03 30.44-44.75c9.391-53.59-28.48-70.16-117.2-95.2c-75.39-21.28-169.2-47.78-151.4-149.7c5.344-30.41 22.78-56.47 49.11-73.39c45.86-29.5 117.2-31.98 211.1-7.359C308 52.16 315.7 65.25 312.4 78.08c-3.344 12.83-16.53 20.44-29.27 17.2C181.9 68.97 132.6 81.45 109.2 96.55C90.03 108.8 83.48 125.8 81.38 137.9c-9.391 53.56 28.48 70.13 117.1 95.17C273.9 254.3 367.8 280.8 349.9 382.7z"
                                                                />
                                                            </svg>
                                                        </span>
                            <span
                                class="flex items-center w-20 ms-2 text-sm"
                            >
                                                            {{ __('A little') }}
                                                        </span>
                        </a>
                    </li>
                    <li>
                        <a
                            class="dropdown-item d-flex"
                            href="#"
                            @click="
                                compose(
                                    'continue',
                                    { length: 'm' }
                                );
                                forceHideFloatigMenu = true;
                                                        "
                        >
                                                        <span
                                                            class="d-flex d-flex icon border rounded"
                                                        >
                                                            <svg
                                                                class="w-100 h-100 p-1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512"
                                                            >
                                                                <path
                                                                    d="M424 480c-13.25 0-24-10.75-24-24V136.1l-155.9 237c-8.875 13.5-31.25 13.5-40.13 0L48 136.1V456C48 469.3 37.25 480 24 480S0 469.3 0 456V56c0-10.61 6.969-19.95 17.12-22.98c10.19-3.078 21.09 .9375 26.94 9.797L224 316.3l179.9-273.5c5.812-8.859 16.66-12.89 26.94-9.797C441 36.05 448 45.39 448 56v400C448 469.3 437.3 480 424 480z"
                                                                />
                                                            </svg>
                                                        </span>
                            <span
                                class="flex items-center w-20 ms-2 text-sm"
                            >
                                                            {{ __('Normal') }}
                                                        </span>
                        </a>
                    </li>
                    <li>
                        <a
                            class="dropdown-item d-flex"
                            href="#"
                            @click="
                                                            compose(
                                                                'continue',
                                                                { length: 'l' }
                                                            );
                                                            forceHideFloatigMenu = true;
                                                        "
                        >
                                                        <span
                                                            class="d-flex d-flex icon border rounded"
                                                        >
                                                            <svg
                                                                class="w-100 h-100 p-1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 320 512"
                                                            >
                                                                <path
                                                                    d="M296 480h-240C42.75 480 32 469.3 32 456V56C32 42.75 42.75 32 56 32S80 42.75 80 56V432h216c13.25 0 24 10.75 24 24S309.3 480 296 480z"
                                                                />
                                                            </svg>
                                                        </span>
                            <span
                                class="flex items-center w-20 ms-2 text-sm"
                            >
                                                            {{ __('A lot') }}
                                                        </span>
                        </a>
                    </li>
                </ul>
            </div>
        </floating-menu>
        <editor-content
            :editor="editorStore.getEditor"
            class="editor__content px-10 px-lg-15 py-10"
        />
        <div
            class="editor__footer bg-white border-top bottom-0 w-100 px-4 py-2"
        >
            <div
                class="d-flex align-self-center align-items-center fs-7 text-gray-600"
            >
                <div class="d-flex w-100">
                    <div
                        class="d-flex border-end me-4"
                    >
                        <div class="d-flex me-2">
                            {{ __('Characters') }}:
                        </div>
                        <div
                            class="d-flex flex-column min-w-25px align-items-end me-4"
                        >
                            <span>{{
                                editorStore.getEditor?.storage?.characterCount.characters() || 0
                            }}</span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="d-flex me-2">
                            {{ __('Words') }}:
                        </div>
                        <div
                            class="d-flex flex-column min-w-25px align-items-end me-4"
                        >
                            <span>{{
                                editorStore.getEditor?.storage?.characterCount.words() || 0
                            }}</span>
                        </div>
                    </div>
                    <div class="d-flex ms-auto autocomplete-adjust">
                        <div
                            class="form-check form-switch form-check-custom form-check-solid me-10"
                        >
                            <input
                                class="form-check-input h-20px w-35px"
                                type="checkbox"
                                v-model="isAutocompleteEnabled"
                                @change="toggleAutocomplete"
                            />
                            <label class="form-check-label" for="org_read">
                                {{ __('Autocomplete') }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LinkModal :isVisible="editorStore.getLinkModal"
                   @update-link="onLinkUpdate"
                   @close="closeLinkModal"
        />
        <DocumentImageModal ref="documentImageModal" @onConfirm="addDocumentImageCommand"/>
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import {defineComponent} from 'vue';
import { TextSelection } from 'prosemirror-state';
import {useToast} from 'vue-toastification';
import {useEditorStore} from '@/Stores/EditorStore';
import {useDocumentStore} from '@/Stores/DocumentStore';
import {useDocumentChatStore} from '@/Stores/DocumentChatStore';
import NProgress from 'nprogress';
import {BubbleMenu, Editor, EditorContent, FloatingMenu} from '@tiptap/vue-3';
import MenuBar from '@/Components/Editor/MenuBar.vue';
import {EditorView} from 'prosemirror-view';
import ResizableImage from '@/TiptapExtensions/resizable-image.js';
import BubbleImageMenu from '@/Layouts/Document/Editor/BubbleImageMenu.vue';
import LinkModal from '@/Components/Editor/LinkModal.vue';
import DocumentImageModal from '@/Modals/DocumentImageModal.vue';
import EditorBubbleMenu from "@/Components/Editor/EditorBubbleMenu.vue";
import ImageBubbleMenu from "@/Components/Editor/ImageBubbleMenu.vue";
import trialLimitExhausted from '@/Mixins/trialLimitExhausted';
import { useUserStore } from '@/Stores/UserStore';
import { useTeamStore } from '@/Stores/TeamStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';

export default defineComponent({
    mixins: [trialLimitExhausted],
    components: {
        EditorBubbleMenu,
        ImageBubbleMenu,
        NProgress,
        EditorContent,
        Image,
        MenuBar,
        FloatingMenu,
        ResizableImage,
        DocumentImageModal,
        LinkModal,
    },

    props: {
        user: Object,
        creations: Object,
    },

    emits: ['onCreate', 'changeImageMeta'],

    setup() {
        // Get editor store
        const editorStore = useEditorStore();

        // Get document store
        const documentStore = useDocumentStore();

        // Get document chat store
        const documentChatStore = useDocumentChatStore();

        // Get user store
        const userStore = useUserStore();

        // Get team store
        const teamStore = useTeamStore();

        // Get subscription store
        const subscriptionStore = useSubscriptionStore();

        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return {
            editorStore,
            documentStore,
            documentChatStore,
            toast,
            userStore,
            teamStore,
            subscriptionStore,
        };
    },

    data() {
        return {
            progressBar: null,
            linkModal: false,
            forceShowBubbleMenu: false,
            forceHideBubbleMenu: false,
            forceShowFloatingMenu: false,
            forceHideFloatingMenu: false,
            textSelection: null,
            selectedText: '',
            selectedCharacters: 0,
            typingTimer: null,
            isPendingSuggestionRequest: false,
            isAutocompleteEnabled:
                this.$page.props.user?.user_info?.settings?.autocomplete ??
                false,
            lastAutocompleteCreationId: null,
            currentAutocompleteRequest: null,
            lastRequestId: null,
            typingDebounceTimer: null,
        };
    },

    watch: {

    },

    mounted() {
        let vm = this;

        this.progressBar = NProgress;

        this.editorStore.initializeEditor({
            onCreate: (editor) => {
                // Emit an event from the component
                vm.$emit('onCreate', editor);

                // Add keydown handler directly to the editor view
                editor.view.dom.addEventListener('keydown', (event) => {
                    if (event.key === 'Tab' && vm.documentStore.getAutoSuggestionResult) {
                        event.preventDefault();
                        editor.commands.acceptSuggestion();
                        vm.documentStore.setAutoSuggestionResult('');
                    }
                });
            },
            onSelectionUpdate: (editor) => {
                // Handle selection update
                const { from, to } = editor.state.selection;
                vm.editorStore.updateSelectedText(editor.state.doc.textBetween(from, to, '\n'));
                vm.editorStore.updateSelectedCharacters(to - from);

            },
            onUpdate: (editor) => {
                vm.editorContent = editor.getJSON();
                vm.documentStore.setContent(editor.getJSON());
            },
        });

        document
            .getElementById('app')
            .addEventListener('continue', function (value) {
                vm.compose('continue');
            });

        emitter.on('document-text-insert', function (copy) {
            vm.editorStore.getEditor.view.dispatch(
                vm.editorStore.getEditor.view.state.tr.insertText(
                    copy,
                    vm.editorStore.getEditor.view.state.selection.from
                )
            );
        });

        emitter.on('document-image-insert', function (imagePath) {
            vm.editorStore.getEditor.chain().focus().setImage({src: imagePath}).run();
        })

        emitter.on('document-image-insert-with-meta', function (imageData) {
            let imageMeta = {
                src: imageData?.src || '',
                alt: imageData?.alt || '',
                class: imageData?.class || '',
                width: imageData?.width || '',
                height: imageData?.height || '',
            }

            vm.editorStore.getEditor.chain().focus().setResizeImage(imageMeta).run();
        })

        this.editorStore.getEditor.view.dom.addEventListener(
            'beforeinput',
            (event) => {
                const text = event.data;
                if (text && text.startsWith('@suggestion:')) {
                    event.preventDefault();
                }
            }
        );

        // eslint-disable-next-line no-undef
        emitter.on(
            'editor-store-dom-events-key-down',
            function ({ view, event }) {
                if (vm.isAutocompleteEnabled) {
                    if (event.keyCode === 9) {
                        if (vm.documentStore.getAutoSuggestionResult) {
                            // Fill the auto suggestion
                            vm.fillAutoSuggestion(view, event);
                        }
                    } else {
                        // Get the position of the cursor in the document
                        let { $from } = view.state.selection;
                        // Get the next node after the cursor
                        let nextNode = view.state.doc.nodeAt($from.pos);
                        // If the next node is a suggestion node and the first character of the suggestion text is the same as the pressed key
                        if (
                            nextNode &&
                            nextNode.type.name === 'suggestion' &&
                            nextNode.textContent.charAt(0) === event.key
                        ) {
                            // Get the remaining text in the suggestion after removing the first character
                            vm.documentStore.setAutoSuggestionResult(
                                nextNode.textContent.slice(1)
                            );
                            // Create a new suggestion node with the remaining text
                            let suggestionType =
                                view.state.schema.nodes.suggestion;
                            let textNode = view.state.schema.text(
                                vm.documentStore.getAutoSuggestionResult
                            );
                            let suggestionNode = suggestionType.create(
                                null,
                                textNode
                            );
                            // Replace the next node with the new suggestion node
                            let { tr } = view.state;
                            tr.replaceWith(
                                $from.pos,
                                $from.pos + nextNode.nodeSize,
                                suggestionNode
                            );
                            view.dispatch(tr);
                        } else {
                            // Delete the suggestion node if exists
                            vm.removeSuggestionText(view);
                            // If the next node is not a suggestion node, run the default behavior
                            clearTimeout(vm.typingTimer);
                            vm.typingTimer = setTimeout(
                                vm.handleKeyDown.bind(null, view, event),
                                500
                            );
                        }
                        if (event.keyCode !== 9 && vm.autoSuggestionResult) {
                            // Check if the pressed key is a valid character (not a control key)
                            if (!vm.isValidCharacterKeyPress(event.keyCode)) {
                                // If the pressed key is not a valid character (e.g., Backspace), remove the suggestion
                                vm.removeSuggestionText(view);
                            }
                        }
                    }
                }
            }
        );

        this.updateEditorOldState();
    },

    beforeUnmount() {
        this.editorStore.destroyEditor();
    },

    methods: {
        onLinkUpdate({ href, target }) {
            let rel = '';
            this.editorStore
                .getEditor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href, target, rel })
                .run();

            this.closeLinkModal();
        },

        closeLinkModal() {
            this.editorStore.setLinkModal(false);
            this.editorStore.setCurrentLinkHref('');
            this.editorStore.setCurrentLinkTarget(false);
        },

        updateEditorOldState() {
            const oldUpdateState = EditorView.prototype.updateState;

            EditorView.prototype.updateState = function (state) {
                // This prevents the matchesNode error on hot reloads
                if (!this.docView) {
                    return;
                }

                oldUpdateState.call(this, state);
            };
        },

        compose(action, options) {
            let vm = this;
            this.progressBar.start();
            this.loading = true;
            if (
                this.teamStore.hasCharacterCredits ||
                this.subscriptionStore.hasUnlimitedUsage
            ) {
                let data = {
                    uuid: this.documentStore.getUuid,
                    prompts: this.prompts,
                    options: {...this.documentStore.getPromptSettings, ...options},
                    lang: this.documentStore.getTargetLang,
                    formality: this.documentStore.getFormality,
                    documentId: this.documentStore.id,
                };

                if (action) {
                    switch (action) {
                        case 'continue':
                            data['continue'] =
                                this.editorStore.getEditor.view.state.doc.textBetween(
                                    0,
                                    this.textSelection.to,
                                    '\n'
                                );
                            break;
                        default:
                            data[action] = this.editorStore.getSelectedText;
                            break;
                    }
                }

                this.composeRequest(data, action, options);
            } else {
                this.progressBar.done();
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
            }
        },

        composeRequest(data, action, options) {
            let vm = this;

            this.progressBar.remove();
            // Fake respond progress
            setTimeout(() => this.progressBar.set(0.4), 500);
            setTimeout(() => this.progressBar.inc(), 1500);
            setTimeout(() => this.progressBar.inc(), 3000);

            let urlAction = 'content';

            if (action) {
                urlAction = action;
            }

            axios
                .post('/api/compose/' + urlAction, data)
                .then((response) => {
                    vm.loading = false;
                    vm.choices = response.data.choices;

                    if (action && action === 'continue') {
                        vm.choices.forEach(function (choice) {
                            vm.editorStore.getEditor.commands.createParagraphNear();
                            let texts = choice.text.split('\n');
                            texts.forEach(function (text) {
                                vm.editorStore.getEditor.commands.insertContent({
                                    type: 'paragraph',
                                    content: [
                                        {
                                            type: 'text',
                                            text: text,
                                        },
                                    ],
                                });
                            });
                            vm.creations.push(choice);
                        });
                        vm.progressBar.done();
                    } else if (action && action === 'paragraph') {
                        vm.editorStore.getEditor.commands.selectTextblockEnd();
                        vm.editorStore.getEditor.commands.createParagraphNear();
                        vm.choices.forEach(function (choice) {
                            let texts = choice.text.split('\n');
                            texts.forEach(function (text) {
                                vm.editorStore.getEditor.commands.insertContent({
                                    type: 'paragraph',
                                    content: [
                                        {
                                            type: 'text',
                                            text: text,
                                        },
                                    ],
                                });
                            });
                            vm.creations.push(choice);
                        });
                        vm.progressBar.done();
                    } else if (action) {
                        vm.choices.forEach(function (choice) {
                            let state = vm.editorStore.getEditor.view.state;
                            let tr = state.tr;
                            let newText = state.schema.text(choice.text);

                            tr.replaceSelectionWith(newText);
                            vm.editorStore.getEditor.view.dispatch(tr);

                            vm.creations.push(choice);
                        });
                        vm.progressBar.done();
                    } else {
                        vm.choices.forEach(function (choice) {
                            let texts = choice.text.split('\n');
                            let contentBlocks = [];
                            contentBlocks.push({
                                type: 'horizontalRule',
                            });

                            texts.forEach(function (text) {
                                if (text) {
                                    contentBlocks.push({
                                        type: 'paragraph',
                                        content: [
                                            {
                                                type: 'text',
                                                text: text,
                                            },
                                        ],
                                    });
                                }
                            });

                            vm.editorStore.getEditor
                                .chain()
                                .createParagraphNear()
                                .insertContent(contentBlocks)
                                .run();
                        });
                        vm.progressBar.done();
                    }

                    Inertia.reload({only: ['user']});
                    vm.progressBar.done();
                })
                .catch(function (error) {
                    vm.toast.error(error.response.data.message);
                    if (
                        error.response.data.error_code ==
                        'trial_credit_limit_exhausted'
                    ) {
                        vm.showTrialLimitExhaustedPopUp();
                    }
                    vm.progressBar.done();
                });
        },

        addDocumentImageCommand(imageData) {
            // Check if the editor instance is available
            if (!this.editor) {
                console.error('Editor instance is not available.');
                return;
            }

            // Deconstruct your imageData object to extract relevant data
            const { src, alt = '', title = '' } = imageData;

            // Use the editor's command chain to insert the image
            this.editorStore.getEditor.chain().focus().setImage({ src, alt, title }).run();
        },

        changeImageMeta() {
            const { node } = this.editorStore.getEditor.state.selection;
            let imageAttributes = node?.attrs ? node?.attrs : {};
            imageAttributes = { ...imageAttributes, ...{ is_editing: true } };

            emitter.emit('document-image-modal-opened', imageAttributes);
        },

        nodeIsResizableImage() {
            return this.editorStore.getEditor.isActive('ResizableImage');
        },
        getLastPhrases(text) {
            // Split the text into sentences. This assumes that sentences end with ". ", "! ", or "? ".
            let sentences = text.match(/[^.!?]*[.!?]/g);
            // If the text does not contain any sentences (as defined above), return the whole text.
            if (!sentences) return text;
            // If the text ends with a partial sentence, add it to the sentences array.
            let lastChar = text[text.length - 1];
            let sentencesLength = 2;
            if (lastChar !== '.' && lastChar !== '!' && lastChar !== '?') {
                let lastSentenceStart =
                    text.lastIndexOf(sentences[sentences.length - 1]) +
                    sentences[sentences.length - 1].length;
                let lastSentence = text.slice(lastSentenceStart).trim();
                if (lastSentence) sentences.push(lastSentence);
                sentencesLength = 3;
            }
            // Get the last two sentences.
            return sentences.slice(-sentencesLength).join(' ');
        },
        isValidCharacterKeyPress(keycode) {
            let valid =
                (keycode > 47 && keycode < 58) || // number keys
                keycode == 32 || // spacebar
                (keycode > 64 && keycode < 91) || // letter keys
                (keycode > 95 && keycode < 112) || // numpad keys
                (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
                (keycode > 218 && keycode < 223); // [\]' (in order)
            return valid;
        },
        handleKeyDown(view, event) {
            // Handle tab key for accepting suggestions
            if (event.key === 'Tab' && this.documentStore.getAutoSuggestionResult) {
                event.preventDefault();
                this.fillAutoSuggestion(view, event);
                return;
            }

            // Clear any existing timer
            if (this.typingDebounceTimer) {
                clearTimeout(this.typingDebounceTimer);
            }

            // Only proceed if it's a valid character key press
            if (!this.isValidCharacterKeyPress(event.keyCode)) {
                return;
            }

            // Set a new timer to call doneTyping after a delay
            this.typingDebounceTimer = setTimeout(() => {
                this.doneTyping(view, event);
            }, 300); // 300ms delay
        },
        doneTyping(view, event) {
            // Always abort any existing request before starting a new one
            if (this.currentAutocompleteRequest) {
                this.currentAutocompleteRequest.abort();
                this.currentAutocompleteRequest = null;
            }

            // If we're still in a pending state from a previous request that didn't complete properly
            if (this.isPendingSuggestionRequest) {
                this.isPendingSuggestionRequest = false;
            }

            let oldCursorPosition = view.state?.selection?.$from?.pos;
            let validKeyPress = this.isValidCharacterKeyPress(event.keyCode);
            let newCursorPosition = view.state?.selection?.$from?.pos;

            // Check if cursor is at the end of a block or beginning of a new block.
            let currentBlock = view.state.selection.$from.parent;
            let currentBlockText = currentBlock.textContent;
            let cursorPositionInBlock = view.state.selection.$from.parentOffset;

            // Exclude suggestion span from current Block.
            let suggestionSpan = '<span class="suggestion">';
            let suggestionSpanIndex = currentBlockText.indexOf(suggestionSpan);
            if (suggestionSpanIndex !== -1) {
                currentBlockText = currentBlockText.substring(0, suggestionSpanIndex);
            }

            let isAtBlockEndOrNewBlock = cursorPositionInBlock === 0 || cursorPositionInBlock === currentBlockText.length;

            // Get text from current block
            let lastPhrases = this.getLastPhrases(currentBlock.textContent);

            if (!validKeyPress || oldCursorPosition !== newCursorPosition || !isAtBlockEndOrNewBlock) {
                return false;
            }

            let vm = this;

            if (this.teamStore.hasCharacterCredits || this.subscriptionStore.hasUnlimitedUsage) {
                let data = {
                    uuid: null,
                    prompts: {
                        description: lastPhrases,
                    },
                    options: { ...this.documentStore.getPromptSettings },
                    lang: this.documentStore.getTargetLang,
                    formality: this.documentStore.getFormality,
                    documentId: this.documentStore.getId,
                };

                // Set pending state before creating the request
                this.isPendingSuggestionRequest = true;

                // Create an AbortController for this request
                const abortController = new AbortController();
                this.currentAutocompleteRequest = abortController;

                // Add a request ID to track this specific request
                const requestId = Date.now();
                this.lastRequestId = requestId;

                // eslint-disable-next-line no-undef
                axios
                    // eslint-disable-next-line no-undef
                    .post(route('compose.autocomplete-suggestion'), data, {
                        signal: abortController.signal
                    })
                    .then((response) => {
                        // Only process the response if this is still the latest request
                        if (requestId !== vm.lastRequestId) {
                            return;
                        }

                        // Get response text from response
                        let responseText = response.data?.choices[0]?.text.replaceAll('\n', ' ');

                        // Extract just the suggestion part from the marked response
                        let match = responseText.match(/<SUGGESTION>(.*?)<\/SUGGESTION>/);
                        if (match) {
                            // Get the full response and the suggestion part
                            let fullResponse = responseText;
                            let suggestionText = match[1];

                            // Get the parts before and after the suggestion
                            let parts = fullResponse.split(/<SUGGESTION>.*?<\/SUGGESTION>/);
                            let beforeSuggestion = parts[0] || '';
                            let afterSuggestion = parts[1] || '';

                            // Get what the user has already typed
                            let userTyped = lastPhrases;

                            // Check if the user's text and the AI's text have a common prefix
                            let commonPrefix = '';
                            let minLength = Math.min(userTyped.length, beforeSuggestion.length);
                            for (let i = 0; i < minLength; i++) {
                                if (userTyped[i] === beforeSuggestion[i]) {
                                    commonPrefix += userTyped[i];
                                } else {
                                    break;
                                }
                            }

                            // If there's a significant common prefix, we can use it to determine what's new
                            if (commonPrefix.length > 3) {
                                // Get the last word the user has typed
                                let userWords = userTyped.substring(commonPrefix.length).trim().split(/\s+/);
                                let lastUserWord = userWords.length > 0 ? userWords[userWords.length - 1] : '';

                                // Get the last word in the AI's text before the suggestion
                                // Get the text before the <SUGGESTION> tag
                                const beforeSuggestionMatch = responseText.match(/(.*?)<SUGGESTION>/s);
                                const textBeforeSuggestion = beforeSuggestionMatch ? beforeSuggestionMatch[1] : '';

                                // Get the suggestion text (everything between <SUGGESTION> tags)
                                const suggestionMatch = responseText.match(/<SUGGESTION>\s*(.*?)\s*<\/SUGGESTION>/s);
                                if (suggestionMatch) {
                                    // Trim any leading/trailing spaces from the suggestion
                                    suggestionText = suggestionMatch[1].trim();
                                }

                                // If the text before suggestion is duplicated in the user's input,
                                // or if it's duplicated at the start of the suggestion, remove it
                                if (textBeforeSuggestion && userTyped.endsWith(textBeforeSuggestion)) {
                                    // Text is already in user's input, just use the suggestion as is
                                } else if (suggestionText.startsWith(textBeforeSuggestion)) {
                                    suggestionText = suggestionText.substring(textBeforeSuggestion.length);
                                }
                            }

                            vm.documentStore.setAutoSuggestionResult(suggestionText);
                            vm.lastAutocompleteCreationId = response.data?.choices[0]?.id;

                            // Set suggestion text
                            vm.editorStore.getEditor.commands.setSuggestion(suggestionText);
                        } else {
                            // Fallback if no marker is found
                            vm.documentStore.setAutoSuggestionResult(responseText);
                            vm.lastAutocompleteCreationId = response.data?.choices[0]?.id;

                            // Set suggestion text
                            vm.editorStore.getEditor.commands.setSuggestion(responseText);
                        }

                        // Reset state after successful completion
                        vm.isPendingSuggestionRequest = false;
                        vm.currentAutocompleteRequest = null;
                    })
                    .catch(function (error) {
                        // Only process the error if this is still the latest request
                        if (requestId !== vm.lastRequestId) {
                            return;
                        }

                        // Only set isPendingSuggestionRequest to false if it's not an abort error
                        if (!axios.isCancel(error)) {
                            console.error("Autocomplete request error:", error);
                            vm.isPendingSuggestionRequest = false;
                            vm.currentAutocompleteRequest = null;
                        }
                    });
            }
        },
        updateSuggestion(view, text) {
            // Get the position of the cursor in the document
            const { $from } = view.state.selection;
            // Get the next node after the cursor
            const nextNode = view.state.doc.nodeAt($from.pos);
            // Create a new suggestion node with the remaining text
            let suggestionType = view.state.schema.nodes.suggestion;
            let textNode = view.state.schema.text(text);
            let suggestionNode = suggestionType.create(null, textNode);
            // Replace the next node with the new suggestion node
            let { tr } = view.state;
            tr.replaceWith(
                $from.pos,
                $from.pos + nextNode.nodeSize,
                suggestionNode
            );
            view.dispatch(tr);
        },
        fillAutoSuggestion(view, event) {
            event.preventDefault();
            this.editorStore.getEditor.commands.acceptSuggestion();
            this.documentStore.setAutoSuggestionResult('');
        },
        removeSuggestionText(view) {
            this.editorStore.getEditor.commands.clearSuggestion();
        },
        toggleAutocomplete() {
            let data = {
                settings: {
                    autocomplete: this.isAutocompleteEnabled,
                },
            };
            // eslint-disable-next-line no-undef
            axios
                .put(
                    '/api/user-infos/' + this.$page.props.user.user_info.id,
                    data
                )
                .then(() => {
                    Inertia.reload({ only: ['user'] });
                });
        },
    },
});
</script>

<style lang="scss">
/* Basic editor styles */
.autocomplete-suggestion {
    pointer-events: none !important;
    user-select: none !important;
    cursor: default !important;
    caret-color: transparent !important;
    position: relative;
    z-index: 1;
}

.ProseMirror {
    > * + * {
        margin-top: 0.75em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    hr {
        border: none;
        border-top: 1px solid rgb(13 13 13 / 51%);
        margin: 2rem 0;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0d0d0d;
        color: #fff;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    &-focused,
    &:focus,
    &:focus-visible {
        border: none;
        outline: none;
    }
}

.editor {
    font-size: 16px;

    &__content {
        padding-top: 30px;
    }

    ::selection {
        background-color: #66c4f796;
        color: white;
    }
}
</style>
