<template>
    <div
        class="modal fade"
        id="kt_modal_ai_article_assistant"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="kt_modal_ai_article_assistant"
        aria-hidden="true"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-fullscreen">
            <!--begin::Modal content-->
            <div class="modal-content bg-gray-100">
                <!--begin::Modal header-->
                <div class="modal-header bg-white">
                    <!--begin::Heading-->
                    <h2>
                        {{ __('Article Wizard') }}
                    </h2>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div
                    class="modal-body article-wizard m-5 mx-0 my-0"
                    v-if="articleWizardStore.getArticleWizardType === 'guided'"
                >
                    <div
                        v-if="showConfirmationDialog"
                        class="confirmation-dialog d-flex h-100 align-items-center justify-content-center"
                    >
                        <div class="w-600px">
                            <!--begin::Alert-->
                            <div
                                class="alert alert-primary bg-light-primary d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10"
                            >
                                <!--begin::Icon-->
                                <i
                                    class="ki-duotone ki-primaryrmation-5 fs-5tx text-primary mb-5"
                                    ><span class="path1"></span
                                    ><span class="path2"></span
                                    ><span class="path3"></span
                                ></i>
                                <!--end::Icon-->

                                <!--begin::Wrapper-->
                                <div class="text-center">
                                    <!--begin::Title-->
                                    <h1 class="fw-bold mb-5">
                                        {{ __('Resume or Restart') }}
                                    </h1>
                                    <!--end::Title-->

                                    <!--begin::Separator-->
                                    <div
                                        class="separator separator-dashed border-primary opacity-25 mb-5"
                                    ></div>
                                    <!--end::Separator-->

                                    <!--begin::Content-->
                                    <div class="mb-9 text-dark">
                                        {{
                                            __(
                                                'You currently have an unfinished article draft. Would you like to continue where you left off or start a new one from scratch?'
                                            )
                                        }}
                                    </div>
                                    <!--end::Content-->

                                    <!--begin::Buttons-->
                                    <div class="d-flex flex-center flex-wrap">
                                        <a
                                            href="#"
                                            @click.stop="startOver"
                                            class="btn btn-outline btn-outline-primary btn-active-danger m-2"
                                            >{{ __('Start Over') }}</a
                                        >
                                        <a
                                            href="#"
                                            @click.stop="
                                                continueWithExistingData
                                            "
                                            class="btn btn-primary m-2"
                                            >{{ __('Continue') }}</a
                                        >
                                    </div>
                                    <!--end::Buttons-->
                                </div>
                                <!--end::Wrapper-->
                            </div>
                            <!--end::Alert-->
                        </div>
                    </div>
                    <HorizontalWizard
                        v-else
                        :user="user"
                        :stepDetails="stepDetails"
                        :step="articleWizardStore.getCurrentStep"
                        :loading="loading"
                        :finishedSteps="articleWizardStore.getFinishedSteps"
                        :stepNumber="stepNumber"
                        :stepNaviagationIcon="stepNaviagationIcon"
                        :headerFullwidth="headerFullwidth"
                        :contentFullwidth="contentFullwidth"
                        :can-proceed="canProceed"
                        @horizontal-wizard-next-step="nextStep"
                        @horizontal-wizard-previous-step="previousStep"
                        @horizontal-wizard-submit="wizardSubmit"
                    >
                        <template v-slot:tabs>
                            <div
                                v-if="
                                    articleWizardStore.getCurrentStep > 0 &&
                                    articleWizardStore.getCurrentStep < 5
                                "
                                class="step-content-left w-50 h-100 pr-5"
                            >
                                <div
                                    class="step-content-inner fv-plugins-bootstrap5 fv-plugins-framework bg-white"
                                >
                                    <!-- Slot for result begins-->
                                    <div
                                        :class="{
                                            current:
                                                articleWizardStore.getCurrentStep ===
                                                0,
                                            'd-none':
                                                articleWizardStore.getCurrentStep !==
                                                0,
                                        }"
                                    >
                                        <Context
                                            :contextText="
                                                articleWizardStore.getContext
                                            "
                                            :showFocusKeyword="true"
                                            :showSearchEngine="false"
                                            @language-change="onLanguageChange"
                                            @formality-change="
                                                onFormalityChange
                                            "
                                        >
                                        </Context>
                                    </div>

                                    <div
                                        class="title-selected"
                                        :class="{
                                            current:
                                                articleWizardStore.getCurrentStep ===
                                                1,
                                            'd-none':
                                                articleWizardStore.getCurrentStep !==
                                                1,
                                        }"
                                    >
                                        <h2 class="text-primary">
                                            {{ __('Title') }}
                                            <span>*</span>
                                        </h2>
                                        <p class="text-muted">
                                            {{
                                                __(
                                                    'Pick a suitable title for your article'
                                                )
                                            }}
                                        </p>
                                        <TitleSelection></TitleSelection>
                                    </div>
                                    <div
                                        class="outlines-selected card h-100"
                                        :class="{
                                            current:
                                                articleWizardStore.getCurrentStep ===
                                                2,
                                            'd-none':
                                                articleWizardStore.getCurrentStep !==
                                                2,
                                        }"
                                    >
                                        <OutlineSelection></OutlineSelection>
                                    </div>

                                    <div
                                        :class="{
                                            current:
                                                articleWizardStore.getCurrentStep ===
                                                3,
                                            'd-none':
                                                articleWizardStore.getCurrentStep !==
                                                3,
                                        }"
                                    >
                                        <h2 class="text-primary">
                                            {{ __('Writing Points') }}
                                        </h2>
                                        <p class="text-muted">
                                            {{
                                                __(
                                                    'Put together build blocks of your article by choosing writing points for\neach heading'
                                                )
                                            }}
                                        </p>
                                        <WritingPoints></WritingPoints>
                                    </div>
                                    <div
                                        :class="[
                                            articleWizardStore.getCurrentStep === 4
                                                ? 'current'
                                                : 'd-none',
                                        ]"
                                    >
                                        <h2 class="text-primary">
                                            {{ __('First Draft') }}
                                        </h2>
                                        <p class="text-sm text-gray-500">
                                            {{
                                                __(
                                                    "Here is your article's first draft! Make changes as you like"
                                                )
                                            }}
                                        </p>
                                        <FirstDraftLeftPanel></FirstDraftLeftPanel>
                                    </div>
                                    <!-- Slot for result ends-->
                                </div>
                            </div>
                            <div
                                class="step-content-right ps-0 h-100 pl-5"
                                :class="{
                                    'w-100':
                                        articleWizardStore.getCurrentStep === 4,
                                    'w-50':
                                        articleWizardStore.getCurrentStep !== 4,
                                }"
                            >
                                <!--begin::Form-->

                                <div
                                    v-if="articleWizardStore.getCurrentStep !== 3"
                                    class="step-content-inner fv-plugins-bootstrap5 fv-plugins-framework bg-white"
                                    id="add_addon_stepper_form"
                                >
                                    <!-- Slot for tab contents begins-->

                                    <!--begin::Step 1-->
                                    <div
                                        :class="{
                                            current:
                                                articleWizardStore.getCurrentStep ===
                                                0,
                                            pending:
                                                articleWizardStore.getCurrentStep !==
                                                0,
                                        }"
                                        data-kt-stepper-element="content"
                                    >
                                        <Context
                                            :contextText="contextText"
                                            :showFocusKeyword="true"
                                            :showSearchEngine="false"
                                            @context-text-update="
                                                onContextTextUpdate
                                            "
                                            @language-change="onLanguageChange"
                                            @formality-change="
                                                onFormalityChange
                                            "
                                            @article-context-search-engine-or-language-changed="
                                                searchEngineSettings = $event
                                            "
                                        >
                                        </Context>
                                    </div>
                                    <!--end::Step 1-->
                                    <!--begin::Step 2-->
                                    <div
                                        :class="{
                                            current:
                                                articleWizardStore.getCurrentStep ===
                                                1,
                                            pending:
                                                articleWizardStore.getCurrentStep !==
                                                1,
                                        }"
                                        data-kt-stepper-element="content"
                                    >
                                        <Title
                                            :aiGeneratedTitles="
                                                articleWizardStore.getAiGeneratedTitles
                                            "
                                            @article-title-selected="
                                                selectedArticleTitle = $event
                                            "
                                            :serpAnalysisTask="serpAnalysisTask"
                                        >
                                        </Title>
                                    </div>
                                    <!--end::Step 2-->
                                    <!--begin::Step 3-->
                                    <div
                                        class="outlines-listing card h-100"
                                        :class="{
                                            current:
                                                articleWizardStore.getCurrentStep ===
                                                2,
                                            pending:
                                                articleWizardStore.getCurrentStep !==
                                                2,
                                        }"
                                        data-kt-stepper-element="content"
                                    >
                                        <Outline></Outline>
                                    </div>
                                    <!--end::Step 3-->
                                    <!--begin::Step 4-->
                                    <div
                                        :class="{
                                            current:
                                                articleWizardStore.getCurrentStep ===
                                                3,
                                            pending:
                                                articleWizardStore.getCurrentStep !==
                                                3,
                                        }"
                                        data-kt-stepper-element="content"
                                    >
                                        <!-- <WritingPoints :aiGeneratedArticleBodies="aiGeneratedArticleBodies"
                                            @article-body-selected="selectedArticleBody = $event"></WritingPoints> -->
                                    </div>
                                    <!--end::Step 4-->
                                    <!--begin::Step 5-->
                                    <div
                                        :class="{
                                            current:
                                                articleWizardStore.getCurrentStep ===
                                                4,
                                            pending:
                                                articleWizardStore.getCurrentStep !==
                                                4,
                                        }"
                                        data-kt-stepper-element="content"
                                    >
                                        <FirstDraft
                                            :lang="articleWizardStore.getLanguage"
                                            :formality="
                                                articleWizardStore.getFormality
                                            "
                                            @loading-changed="loading = $event"
                                        >
                                        </FirstDraft>
                                    </div>
                                    <!--end::Step 5-->
                                </div>
                                <!--end::Form-->
                            </div>
                        </template>
                        <template v-slot:footer-middle>
                            <!--                          <div class="footer-middle-content">
                            <div class="counting-wrapper">
                              <div class="word-count">
                                <span class="counting-number">0</span>
                                <span class="counting-text">{{ __('Words') }}</span>
                              </div>
                              <div class="character-count">
                                <span class="counting-number">0</span>
                                <span class="counting-text">{{ __('Characters') }}</span>
                              </div>
                            </div>
                          </div>-->
                        </template>
                    </HorizontalWizard>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import { watch } from 'vue';
import {router as Inertia} from '@inertiajs/vue3';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import HorizontalWizard from '@/Components/Wizard/HorizontalWizard.vue';
import Context from '@/Layouts/Article/Partials/Wizard/Guided/Context.vue';
import Title from '@//Layouts/Article/Partials/Wizard/Guided/Title.vue';
import TitleSelection from '@//Layouts/Article/Partials/Wizard/Guided/Partials/TitleSelection.vue';
import OutlineSelection from '@//Layouts/Article/Partials/Wizard/Guided/OutlineSelection.vue';
import Outline from '@//Layouts/Article/Partials/Wizard/Guided/Outline.vue';
import WritingPoints from '@//Layouts/Article/Partials/Wizard/Guided/WritingPoints.vue';
import FirstDraft from '@//Layouts/Article/Partials/Wizard/Guided/FirstDraft.vue';
import FirstDraftLeftPanel from '@//Layouts/Article/Partials/Wizard/Guided/FirstDraftLeftPanel.vue';
import { useToast } from 'vue-toastification';
import { VueDraggableNext } from 'vue-draggable-next';
import { Modal } from 'bootstrap';
import MarkdownIt from 'markdown-it';
import trialLimitExhausted from '@/Mixins/trialLimitExhausted';
const md = new MarkdownIt();

export default {
    mixins: [trialLimitExhausted],
    setup() {
        // Get document chat store
        const articleWizardStore = useArticleWizardStore();

        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        watch(
            () => articleWizardStore.shouldCheckForOngoingArticle,
            (newValue) => {
                if (newValue) {
                    if (articleWizardStore.hasOngoingArticle) {
                        this.showConfirmationDialog = true;
                    }
                    // Reset the flag in the store
                    articleWizardStore.toggleCheckForOngoingArticle();
                }
            }
        );

        return { articleWizardStore, toast };
    },

    props: {
        user: Object,
    },

    components: {
        HorizontalWizard,
        Context,
        Title,
        TitleSelection,
        OutlineSelection,
        Outline,
        WritingPoints,
        FirstDraft,
        FirstDraftLeftPanel,
        draggable: VueDraggableNext,
    },

    data() {
        return {
            modal: {},
            showConfirmationDialog: false,
            loading: false,
            stepNumber: true,
            stepNaviagationIcon: true,
            headerFullwidth: true,
            contentFullwidth: true,
            stepDetails: [
                {
                    title: this.__('Context'),
                },
                {
                    title: this.__('Title'),
                },
                {
                    title: this.__('Outline'),
                },
                {
                    title: this.__('Writing Points'),
                },
                {
                    title: this.__('First Draft'),
                },
            ],
            contextText: '',
            selectedArticleTitle: '',
            aiGeneratedArticleBodies: [],
            selectedArticleBody: '',
            aiCommandUuids: {
                headline: '2596c5a7-1dcf-49f9-a6c6-4ab8ff8ff44b',
                articleOutline: 'fa689328-cb21-4f49-b9b7-f36415bd93cf',
                articleBody: 'f156e123-117b-4d64-9851-b48076a2af61',
            },
            options: {
                creativity: 0.75,
                outputs: 3,
                withEmojis: false,
                frequency_penalty: '0.5',
                presence_penalty: '0.5',
            },
            lang:
                this.$page.props.user.user_info.prompt_settings &&
                this.$page.props.user.user_info.prompt_settings.targetLang
                    ? this.$page.props.user.user_info.prompt_settings.targetLang
                    : 'en',
            formality:
                this.$page.props.user.user_info.prompt_settings &&
                this.$page.props.user.user_info.prompt_settings.formality !==
                    undefined
                    ? this.$page.props.user.user_info.prompt_settings.formality
                    : 'default',
            searchEngineSettings: {},
            serpAnalysisTask: {},
            allowedLanguages: [],
        };
    },

    mounted() {
        let query = this.getUserQueryIfExists();

        this.modal = new Modal(
            document.getElementById('kt_modal_ai_article_assistant')
        );

        if (query) {
            this.articleWizardStore.resetState();
            this.articleWizardStore.setContext(query);
            this.articleWizardStore.setArticleWizardType('guided');
            this.modal.show();
        } else {
            this.checkForOngoingArticle();

            document
                .getElementById('kt_modal_ai_article_assistant')
                .addEventListener('show.bs.modal', this.checkForOngoingArticle);
        }
    },

    beforeUnmount() {
        document
            .getElementById('kt_modal_ai_article_assistant')
            .removeEventListener('show.bs.modal', this.checkForOngoingArticle);
        this.modal.hide();
    },

    methods: {
        getUserQueryIfExists() {
            const urlParams = new URLSearchParams(window.location.search);
            const query = urlParams.get('query');

            return query || '';
        },
        checkForOngoingArticle() {
            if (this.articleWizardStore.getSerpBeaterMode) {
                this.articleWizardStore.resetState();
            } else if (this.articleWizardStore.hasOngoingArticle) {
                this.showConfirmationDialog = true;
            }
        },

        continueWithExistingData() {
            this.showConfirmationDialog = false;
        },

        startOver() {
            this.articleWizardStore.resetState();
            this.showConfirmationDialog = false;
        },

        async nextStep() {
            if (this.articleWizardStore.getCurrentStep === 0) {
                await this.fetchArticleTitles();
                this.completedStepCheck();
            } else if (this.articleWizardStore.getCurrentStep === 1) {
                await this.fetchArticleOutlines();
                this.completedStepCheck();
            } else if (this.articleWizardStore.getCurrentStep === 2) {

                if(this.articleWizardStore.getAiGeneratedWritingPoints.length === 0) {
                    let outlines = this.getArticleOutlineRequestFormat(
                        this.articleWizardStore.getArticleOutline
                    )

                    this.articleWizardStore.setAiGeneratedWritingPoints(
                        outlines
                    );
                }

                this.articleWizardStore.setCurrentStep(
                    this.articleWizardStore.getCurrentStep + 1
                );

                this.completedStepCheck();
            } else if (this.articleWizardStore.getCurrentStep === 3) {
                this.articleWizardStore.setCurrentStep(
                    this.articleWizardStore.getCurrentStep + 1
                );
                this.completedStepCheck();
            }
        },

        completedStepCheck() {
            if (
                this.articleWizardStore.getFinishedSteps <=
                this.articleWizardStore.getCurrentStep
            ) {
                this.articleWizardStore.getFinishedSteps =
                    this.articleWizardStore.getCurrentStep;
            }
        },

        previousStep() {
            if (3 == this.articleWizardStore.getCurrentStep) {
                this.articleWizardStore.setEmptyWritingPoints();
            }
            if (4 == this.articleWizardStore.getCurrentStep) {
                this.articleWizardStore.clearArticleDraftContentSection('introduction');
                this.articleWizardStore.clearArticleDraftContentSection('heading_lists');
                this.articleWizardStore.clearArticleDraftContentSection('faq_lists');
                this.articleWizardStore.clearArticleDraftContentSection('conclusion');
            }
            this.articleWizardStore.setCurrentStep(
                this.articleWizardStore.getCurrentStep - 1
            );
        },

        onContextTextUpdate(ev) {
            this.contextText = ev;
            this.articleWizardStore.setContext(ev);
        },

        onLanguageChange(ev) {
            this.lang = ev;
            this.articleWizardStore.setLanguage(ev);
        },

        onFormalityChange(ev) {
            this.formality = ev;
        },

        wizardSubmit() {
            this.loading = true;
            const draftContent = this.articleWizardStore?.getArticleDraftContent || {};
            const draftIntroduction = draftContent?.introduction || [];
            const draftHeadingContents = draftContent?.heading_lists || [];
            const draftFaqContents = draftContent?.faq_lists || [];
            const draftConclusion = draftContent?.conclusion || [];
            let htmlContent = '';

            htmlContent += this.articleWizardStore?.getArticleTitle
                ? `<h1>${this.articleWizardStore.getArticleTitle}</h1>`
                : '';

            draftIntroduction.forEach((introduction) => {
                htmlContent += `<h2>${introduction.heading}</h2>`;
                htmlContent += md.render(introduction.content);
            });

            draftHeadingContents.forEach((headingContent) => {
                htmlContent += `<h2>${headingContent.heading}</h2>`;
                htmlContent += md.render(headingContent.content);

                const subHeadingContents = headingContent?.sub_headings || [];

                subHeadingContents?.forEach((subHeadingContent) => {
                    htmlContent += `<h3>${subHeadingContent.heading}</h3>`;
                    htmlContent += md.render(subHeadingContent.content);
                });
            });

            if (draftFaqContents.length > 0) {
                htmlContent += `<h2>${this.__('Frequently Asked Questions')}</h2>`;
                draftFaqContents.forEach((faq) => {
                    htmlContent += `<h2>${faq.heading}</h2>`;
                    htmlContent += md.render(faq.content);
                });
            }

            draftConclusion.forEach((conclusion) => {
                htmlContent += `<h2>${conclusion.heading}</h2>`;
                htmlContent += md.render(conclusion.content);
            });

            if (htmlContent) {
                this.saveArticleAsDocumentContent(htmlContent);
            }
        },
        getHeadingContents(headingObject) {
            for (let key in headingObject) {
                if (headingObject.hasOwnProperty(key)) {
                    return headingObject[key];
                }
            }
        },
        saveArticleAsDocumentContent(htmlContent) {
            let data = {
                name: this.articleWizardStore.getArticleTitle,
                content: htmlContent,
                meta: {
                    title: '',
                    description: '',
                    domain: '',
                    slug: '',
                },
                keyword: this.articleWizardStore.getFocusKeyword,
            };

            axios
                .post('/api/article/wizard/save-document', data)
                .then((response) => {
                    this.articleWizardStore.setIsError(false);
                    let documentUrl = `${window.location.origin}/documents/${response.data?.document?.uuid}`;
                    this.articleWizardStore.resetState();
                    window.location.assign(documentUrl);
                })
                .catch((error) => {
                    if (!error.response) {
                        if(this.articleWizardStore.isError){
                            this.toast.error(this.__('Something went wrong! Please try in few minutes!'));
                        } else {
                            this.toast.error(this.__('Something went wrong! Please try again!'));
                            this.articleWizardStore.setIsError(true);
                        }
                    } else {
                        this.toast.error(this.__('Some error occurred'));
                    }
                    this.loading = false;
                });
        },
        async fetchArticleTitles() {
            this.loading = true;
            let vm = this;

            let options = this.options;
            options.outputs = 6;

            let data = {
                uuid: this.aiCommandUuids.headline,
                prompts: {
                    description: this.articleWizardStore.getContext,
                    name: '',
                },
                options: { ...options, ...{ outputs: 6 } },
                lang: this.articleWizardStore.getLanguage,
                formality: this.articleWizardStore.getFormality,
                search_engine_settings: this.searchEngineSettings,
            };
            if (this.userTeamHasEnoughCredits || this.userHasUnlimitedUsage) {
                axios
                    .post('/api/article/ai-assistant/headline', data)
                    .then((response) => {
                        vm.articleWizardStore.setAiGeneratedTitles(
                            JSON.parse(response.data.choices[0].text)
                        );
                        //vm.serpAnalysisTask = response.data.serpAnalysisTask ? response.data.serpAnalysisTask : {};
                        vm.loading = false;
                        vm.articleWizardStore.setCurrentStep(
                            vm.articleWizardStore.getCurrentStep + 1
                        );
                        Inertia.reload({ only: ['user'] });
                    })
                    .catch(function (error) {
                        if (!error.response) {
                            if(vm.articleWizardStore.isError){
                                vm.toast.error(vm.__('Something went wrong! Please try in few minutes!'));
                            } else {
                                vm.toast.error(vm.__('Something went wrong! Please try again!'));
                                vm.articleWizardStore.setIsError(true);
                            }
                        } else {
                            vm.toast.error(error.response.data.message);
                            if (
                                error.response.data.error_code ==
                                'trial_credit_limit_exhausted'
                            ) {
                                vm.showTrialLimitExhaustedPopUp();
                            }
                        }

                        vm.loading = false;
                    });
            } else {
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
                this.loading = false;
            }
        },
        async fetchArticleOutlines() {
            this.loading = true;
            let vm = this;

            let data = {
                prompts: {
                    description: this.articleWizardStore.getArticleTitle,
                    name: '',
                },
                options: { ...this.articleWizardStore.getOpenAiOptions },
                lang: this.articleWizardStore.getLanguage,
                formality: this.articleWizardStore.getFormality,
                headline: this.articleWizardStore.getArticleTitle,
                outline: this.articleWizardStore.getArticleOutline,
                keyword: this.articleWizardStore.getFocusKeyword,
            };

            vm.articleWizardStore.setArticleOutlineRequestPayload(data);

            if (this.userTeamHasEnoughCredits || this.userHasUnlimitedUsage) {
                vm.articleWizardStore.setCurrentStep(
                    this.articleWizardStore.getCurrentStep + 1
                );
                vm.loading = false;
            } else {
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
            }
        },
        getArticleOutlineRequestFormat(outline) {
            let headingStructure = outline?.headings?.map((mainHeading) => {
                let subHeadingsArray = mainHeading.subHeadings.map(subHeading => {
                    return {[subHeading.name]: []};
                });

                return {
                    heading_type_2: {
                        [mainHeading.name]: [],
                    },
                    heading_type_3: subHeadingsArray,
                    collapsed: false,
                };
            });

            let faqStructure = outline?.faqs?.map((faq) => {
                return {
                    heading_type_2: {
                        [faq.question]: [],
                    },
                    collapsed: false,
                };
            });

            return {
                heading_lists: headingStructure,
                faq_lists: faqStructure,
            };
        },
    },
    computed: {
        userTeamHasEnoughCredits() {
            return (
                this.user.current_team.team_plan_credits.character_credits >
                    0 ||
                this.user.current_team.team_bonus_credits.character_credits > 0
            );
        },
        userHasUnlimitedUsage() {
            let user = this.user;
            let plans = this.getPlans();

            // Check if the user has a normal standard or professional subscription
            let hasNormalSubscription = user.current_team.subscriptions.some(
                (subscription) => {
                    const paddlePlanId = subscription.paddle_plan;
                    const professionalPlans = plans.professional;
                    const standardPlans = plans.standard;

                    return (
                        professionalPlans.includes(paddlePlanId) ||
                        standardPlans.includes(paddlePlanId)
                    );
                }
            );

            // Check if the user has a special lifetime subscription based on plan name or having more than one lifetime subscription
            let hasLifetimeSubscription =
                user.current_team.lifetime_subscriptions.some(
                    (subscription) => {
                        // Check if the plan name is "professional" or "standard"
                        if (
                            subscription.plan &&
                            ['professional', 'standard'].includes(
                                subscription.plan.toLowerCase()
                            )
                        ) {
                            return true;
                        } else if (
                            subscription.plan &&
                            ['basic'].includes(subscription.plan.toLowerCase())
                        ) {
                            // Check if the plan name is "basic"
                            return false;
                        }

                        // Check if the user has more than one lifetime subscription
                        return (
                            user.current_team.lifetime_subscriptions.length > 0
                        );
                    }
                );

            // Return true if the user has a normal subscription or a special lifetime subscription
            return hasNormalSubscription || hasLifetimeSubscription;
        },
        canProceedStep0: function () {
            return this.articleWizardStore.getContext.length > 2;
        },
        canProceedStep1: function () {
            return this.articleWizardStore.getArticleTitle !== '';
        },
        canProceedStep2: function () {
            const articleOutline = this.articleWizardStore.getArticleOutline;

            return articleOutline?.headings?.length > 0;
        },
        canProceedStep3: function () {
            return (
                this.articleWizardStore.getAiGeneratedWritingPoints.length !== 0
            );
        },
        canProceedStep4: function () {
            return (
                this.articleWizardStore.getArticleDraftContent.heading_lists
                    .length !== 0
            );
        },
        canProceed: function () {
            if (this.articleWizardStore.getCurrentStep === 0) {
                return this.canProceedStep0;
            } else if (this.articleWizardStore.getCurrentStep === 1) {
                return this.canProceedStep1;
            } else if (this.articleWizardStore.getCurrentStep === 2) {
                return this.canProceedStep2;
            } else if (this.articleWizardStore.getCurrentStep === 3) {
                return this.canProceedStep3;
            } else if (this.articleWizardStore.getCurrentStep === 4) {
                return this.canProceedStep4;
            }
        },
    },
};
</script>

<style lang="scss">
.article-wizard {
    .stepper {
        &.stepper-links {
            .stepper-nav {
                &.fullwidth {
                    .stepper-item {
                        &:first-child {
                            min-width: 13%;
                        }

                        &.current {
                            &:after {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    .outlines-selected {
        .list-group-item .list-group-item {
            border: none;
            background-color: transparent;

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }

        .h3-element {
            margin-left: 10px;
        }

        .list-group-item + .list-group-item {
            border-top-width: 0;
        }

        .list-group-item {
            border-radius: 0;

            &:hover,
            &:focus {
                background-color: rgba(54, 153, 255, 0.1);
            }
        }
    }

    .title-selected {
        .sortable-ghost {
            display: none !important;
        }
    }

    .outline-elements {
        .h2-element {
            margin-left: 10px;

            span {
                &.arrow-up {
                    float: right;

                    i {
                        color: var(--bs-link-color);
                    }
                }
            }
        }

        .h3-element {
            margin-left: 10px;
        }

        .list-group-item {
            border-radius: 0;

            .add-action {
                span {
                    &.arrow-left {
                        i {
                            color: transparent;
                        }
                    }
                }
            }

            &:hover,
            &:focus {
                background-color: rgba(54, 153, 255, 0.1);

                > div > .add-action {
                    span {
                        &.arrow-left {
                            i {
                                color: var(--bs-link-color);
                            }
                        }
                    }
                }
            }
        }
    }

    .h3-element-wrapper {
        &:hover {
            .btn-remove {
                display: block !important;
            }
        }
    }

    span {
        &.arrow-up {
            float: right;

            i {
                color: var(--bs-link-color);
            }
        }
    }
}
</style>
