import { Extension } from '@tiptap/core';
import { Plugin, TextSelection, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

const suggestionPluginKey = new PluginKey('suggestion');

const SuggestionNode = Extension.create({
    name: 'suggestion',

    addStorage() {
        return {
            suggestion: {
                text: null,
                position: null,
            },
        };
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: suggestionPluginKey,
                props: {
                    decorations: (state) => {
                        const { doc } = state;
                        const decorations: Decoration[] = [];
                        const { text: suggestionText, position } = this.editor?.storage?.suggestion || {};

                        if (suggestionText && position !== null) {
                            decorations.push(
                                Decoration.widget(position, () => {
                                    const span = document.createElement('span');
                                    span.className = 'autocomplete-suggestion text-gray-400';
                                    span.setAttribute('contenteditable', 'false');
                                    span.setAttribute('data-suggestion', 'true');
                                    span.setAttribute('aria-hidden', 'true');
                                    span.style.cssText = 'pointer-events: none !important; user-select: none !important; cursor: default !important; caret-color: transparent !important;';
                                    span.textContent = suggestionText;
                                    return span;
                                }, { stopEvent: () => true })
                            );
                        }

                        return DecorationSet.create(doc, decorations);
                    },
                },
            }),
        ];
    },
    addCommands() {
        return {
            setSuggestion: (suggestionText) => ({ editor, state }) => {
                editor.storage.suggestion.text = suggestionText;
                editor.storage.suggestion.position = state.selection.$head.pos;
                return true;
            },
            clearSuggestion: () => ({ editor }) => {
                editor.storage.suggestion.text = null;
                editor.storage.suggestion.position = null;
                return true;
            },
            acceptSuggestion: () => ({ editor, state, tr, dispatch }) => {
                const { text: suggestionText, position } = editor.storage.suggestion;
                if (!suggestionText || position === null) return false;

                if (dispatch) {
                    tr.insertText(suggestionText, position);
                    dispatch(tr);
                }

                editor.commands.clearSuggestion();
                return true;
            },
        };
    },
});

export default SuggestionNode;
